<template>
  <!-- Search Modal -->
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-20" @close="emit('close')">
      <!-- <div class="fixed inset-0" /> -->
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="
              pointer-events-none
              fixed
              inset-y-0
              right-0
              flex
              max-w-full
              pl-10
              sm:pl-16
            "
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form
                  class="
                    flex
                    h-full
                    flex-col
                    divide-y divide-gray-200
                    bg-white
                    shadow-xl
                  "
                >
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-blue-600 py-6 px-4 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-lg font-medium text-white"
                          >Filter Logs</DialogTitle
                        >
                        <div class="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            class="
                              rounded-md
                              bg-blue-600
                              text-blue-200
                              hover:text-white
                              focus:outline-none focus:ring-2 focus:ring-white
                            "
                            @click="emit('close')"
                          >
                            <span class="sr-only">Close panel</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pt-6 pb-5">
                          <div class="flex items-left flex-col">
                            <label
                              for="limits"
                              class="block text-sm font-medium text-gray-900"
                              >Number of Records</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="form.limit"
                                id="limits"
                                name="limits"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option
                                  v-for="limit in LIMITS"
                                  :key="limit"
                                  :value="limit"
                                >
                                  {{ limit }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="flex items-left flex-col">
                            <label
                              for="order"
                              class="block text-sm font-medium text-gray-900"
                              >Order</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="form.order"
                                id="order"
                                name="order"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option
                                  v-for="key in Object.keys(ORDERS)"
                                  :key="key"
                                  :value="ORDERS[key]"
                                  :selected="form.order == ORDERS[key]"
                                >
                                  {{ camelCaseString(key) }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <label
                              for="filter-date-presets"
                              class="block text-sm font-medium text-gray-900"
                              >Date Presets</label
                            >
                            <div class="mt-1">
                              <select
                                v-model="datePreset"
                                @change="handlePresetChange($event)"
                                id="location"
                                name="location"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              >
                                <option
                                  v-for="key in Object.keys(DATE_PRESETS)"
                                  :key="key"
                                  :value="DATE_PRESETS[key]"
                                >
                                  {{ camelCaseString(key) }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <label
                              for="start-date"
                              class="block text-sm font-medium text-gray-900"
                              >Start Date</label
                            >
                            <div class="mt-1">
                              <input
                                :disabled="datePreset != DATE_PRESETS.CUSTOM"
                                v-model="dateStartInput"
                                type="date"
                                id="start-date"
                                name="start-date"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="end-date"
                              class="block text-sm font-medium text-gray-900"
                              >End Date</label
                            >
                            <div class="mt-1">
                              <input
                                :disabled="datePreset != DATE_PRESETS.CUSTOM"
                                v-model="dateEndInput"
                                type="date"
                                id="end-date"
                                name="end-date"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="filter-from"
                              class="block text-sm font-medium text-gray-900"
                              >Sender</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="form.from"
                                type="text"
                                name="filter-from"
                                id="filter-from"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="filter-to"
                              class="block text-sm font-medium text-gray-900"
                              >TO</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="form.to"
                                type="text"
                                name="filter-to"
                                id="filter-to"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              for="filter-cc"
                              class="block text-sm font-medium text-gray-900"
                              >CC</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="form.cc"
                                type="text"
                                name="filter-cc"
                                id="filter-cc"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="filter-bcc"
                              class="block text-sm font-medium text-gray-900"
                              >BCC</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="form.bcc"
                                type="text"
                                name="filter-bcc"
                                id="filter-bcc"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="filter-subject"
                              class="block text-sm font-medium text-gray-900"
                              >Subject</label
                            >
                            <div class="mt-1">
                              <input
                                v-model="form.subject"
                                type="text"
                                name="filter-subject"
                                id="filter-subject"
                                class="
                                  block
                                  w-full
                                  rounded-md
                                  border-gray-300
                                  shadow-sm
                                  focus:border-blue-500 focus:ring-blue-500
                                  sm:text-sm
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      class="
                        rounded-md
                        border border-gray-300
                        bg-white
                        py-2
                        px-4
                        text-sm
                        font-medium
                        text-gray-700
                        shadow-sm
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-blue-500
                        focus:ring-offset-2
                      "
                      @click="emit('close')"
                    >
                      Cancel
                    </button>
                    <button
                      @click="handleSubmitClick()"
                      type="button"
                      class="
                        ml-4
                        inline-flex
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-blue-600
                        py-2
                        px-4
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-blue-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-blue-500
                        focus:ring-offset-2
                      "
                    >
                      Filter
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>

import { defineProps, ref } from "vue";
import moment from "moment";

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid'

import { useSessionStore } from "@/stores/sessionv2";

import {
  Order as ORDERS,
} from "@preava/preava-prevent-api-grpc-web-js/filters_pb";

import { camelCaseString } from "@/utilities/helpers"

const STORE_SESSION = useSessionStore();


const props = defineProps({
  open: Boolean,
});

const emit = defineEmits(['close'])

// enums for date presets
const DATE_PRESETS = Object.freeze({
  TODAY: 1,
  YESTERDAY: 2,
  THIS_WEEK: 3,
  LAST_WEEK: 4,
  THIS_MONTH: 5,
  LAST_MONTH: 6,
  THIS_YEAR: 7,
  CUSTOM: 8,
});

// enums for date presets
const LIMITS = [50, 100, 250, 500, 1000];


// const ORDERS = Object.freeze({
//   ASC: 1,
//   DESC: 2,
// });

// form inputs to be passed as payload
let form = ref({
  subject: "",
  from: "",
  to: "",
  cc: "",
  bcc: "",
  dateStart: moment().startOf("year").unix(), // this will be in unix format for api
  dateEnd: moment().unix(), // this will be in unix format for api
  limit: LIMITS[0], // 50
  order: ORDERS.DESCENDING
});

// these variables are for form date formatting
// set TODAY as default
let datePreset = ref(DATE_PRESETS.THIS_YEAR);
let dateStartInput = ref(moment.unix(form.value.dateStart).format("YYYY-MM-DD"));
let dateEndInput = ref(moment.unix(form.value.dateEnd).format("YYYY-MM-DD"));

const handleSubmitClick = () => {

  console.log(form.value)
  let payload = {...form.value, traceId: STORE_SESSION.traceId}

  payload.traceId = STORE_SESSION.traceId;

  if(datePreset.value == DATE_PRESETS.CUSTOM){
    payload.dateStart = moment(dateStartInput.value).startOf("day").unix();
    payload.dateEnd = moment(dateEndInput.value).endOf("day").unix();
  }



  STORE_SESSION.updateFilter(payload);
  STORE_SESSION.fetchEvents(payload);

  emit('close')
};

// handler for when the date is changed
const handlePresetChange = function (event) {
  let start = null;
  let end = null;
  switch (parseInt(event.target.value)) {
    case DATE_PRESETS.TODAY:
      start = moment().startOf("day");
      end = moment();
      break;
    case DATE_PRESETS.YESTERDAY:
      start = moment().subtract(1, "day").startOf("day");
      end = moment().subtract(1, "day").endOf("day");
      break;
    case DATE_PRESETS.THIS_WEEK:
      start = moment().startOf("week");
      end = moment().endOf("week");
      break;
    case DATE_PRESETS.THIS_MONTH:
      start = moment().startOf("month");
      end = moment().endOf("month");
      break;
    case DATE_PRESETS.LAST_WEEK:
      start = moment().subtract(1, "weeks").startOf("week");
      end = moment().subtract(1, "weeks").endOf("week");
      break;
    case DATE_PRESETS.LAST_MONTH:
      start = moment().subtract(1, "month").startOf("month");
      end = moment().subtract(1, "month").endOf("month");
      break;
    case DATE_PRESETS.THIS_YEAR:
      start = moment().startOf("year");
      end = moment().endOf("year");
      break;
    case DATE_PRESETS.CUSTOM:
      start = moment().startOf("day");
      end = moment().endOf("day");
      break;
    default:
      break;
  }
  // prepare values for fetching, but in unix() seconds
  form.value.dateStart = start.unix();
  form.value.dateEnd = end.unix();
  // prepare values for ui, but in YYYY-MM-DD format for HTML input value
  dateStartInput.value = start.format("YYYY-MM-DD");
  dateEndInput.value = end.format("YYYY-MM-DD");
  // if you want to debug the time and date, uncomment below
  // console.log(start.format("YYYY-MM-DD hh:mm"));
  // console.log(end.format("YYYY-MM-DD hh:mm"));
};

</script>
