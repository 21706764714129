<template>
  <div @mouseover="hover = true" @mouseleave="hover = false" class="tooltip__container">
    <ExclamationIcon class="tooltip__icon--warning" />
    <div v-if="hover" class="tooltip__wrapper">
      <span class="tooltip__message">{{ message }}</span>
      <div class="tooltip__arrow"></div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";
export default {
  props: ["message"],
  components: {
    ExclamationIcon
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
<style scoped>
  @import './tooltip.css';
</style>