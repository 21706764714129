<template>
  <table class="w-full divide-y divide-gray-300 table-fixed">
    <thead class="bg-gray-50">
      <tr>
        <th scope="col" class="w-2/12 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6">From</th>
        <th scope="col" class="w-3/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Recipients</th>
        <th scope="col" class="w-3/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Subject</th>
        <th scope="col" class="w-2/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Timestamp</th>
        <!-- <th scope="col" class="w-2/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Source</th> -->
        <th scope="col" class="w-16 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6 truncate">Source</th>
        <th scope="col" class="w-8 relative py-3.5 pl-3 pr-4">
          <span class="sr-only">Edit</span>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-for="i in 7" :key="i">
        
        <!--  -->

        <td class="w-1/5 whitespace-nowrap py-4 pl-4 pr-4 font-medium text-gray-900 sm:pl-6">
          <div class="flex items-center">
            <div class="w-full">
              <div
                class="
                  font-medium
                  text-sm text-gray-900
                  truncate
                  bg-gray-100
                  w-40
                  rounded-md
                "
              >
                <span class="animate-pulse">&nbsp;</span>
              </div>
              <div
                class="
                  text-xs
                  font-normal
                  text-gray-500
                  shrink
                  truncate
                  bg-gray-100
                  my-1
                  h-2
                  w-40
                  rounded-md
                "
              >
                <span class="animate-pulse">&nbsp;</span>
              </div>
            </div>
          </div>
        </td>

        <!--  -->
        
        <td class="w-1/5 whitespace-nowrap p-4 text-sm text-gray-500 space-y-1">
          <div class="items-center space-x-1 truncate flex">
            <span class="text-xs text-gray-500 uppercase font-bold">To:</span>
            <span class="text-xs text-gray-500 shrink max-w-60 truncate bg-gray-100 w-48 h-4 rounded-md animate-pulse"></span>
            <!-- <span class="text-xs text-gray-500">+8 more</span> -->
          </div>
          <div class="items-center space-x-1 truncate flex">
            <span class="text-xs text-gray-500 uppercase font-bold">Cc:</span>
            <span class="text-xs text-gray-500 shrink max-w-60 truncate bg-gray-100 w-48 h-4 rounded-md animate-pulse"></span>
            <!-- <span class="text-xs text-gray-500">+1 more</span> -->
          </div>
          <div class="items-center space-x-1 truncate flex">
            <span class="text-xs text-gray-500 uppercase font-bold">Bcc:</span>
            <span class="text-xs text-gray-500 shrink max-w-60 truncate bg-gray-100 w-48 h-4 rounded-md animate-pulse"></span>
            <!-- <span class="text-xs text-gray-500">+8 more</span> -->
          </div>
        </td>

        <!--  -->

        <td class="py-4 pl-4 pr-3 text-sm overflow-wrap">
          
          <p class="font-medium text-sm w-full text-gray-900 truncate bg-gray-100 w-48 h-4 rounded-md animate-pulse">
            
          </p>
        </td>

        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
          <div class="text-gray-500 text-xs truncate flex flex-col space-y-1">
            <span class="text-ellipsis flex items-center">
              <svg class="h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
              </svg>
              <span class="truncate bg-gray-100 w-32 h-3 rounded-md animate-pulse"></span>
            </span>
            <span class="text-ellipsis flex items-center">
              <svg class="h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span class="truncate bg-gray-100 w-32 h-3 rounded-md animate-pulse"></span>
            </span>
          </div>
        </td>


        <td class="w-1/5 whitespace-nowrap p-4 text-sm text-gray-500 space-y-1">
          <div class="items-center space-x-1 truncate">
            <p class="text-xs bg-gray-100 w-20 h-3 rounded-md animate-pulse"></p>
          </div>
        </td>

        <td class="relative whitespace-nowrap text-center text-sm font-medium">
          <button class="bg-blue-100 opacity-50 hover:text-blue-900 w-12 h-3 rounded-md animate-pulse"
            ></button
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>