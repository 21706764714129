<template>
  <div class="flex flex-col h-full px-8 py-6 bg-gray-50 space-y-10 animate-pulse">
          
    <div class="flex my-1 flex-col space-y-3">
      <div class="h-8 bg-gray-200 w-28"></div>
      <div class="h-9 bg-gray-100 w-2/3"></div>
    </div>

    <div class="w-full h-10 flex space-x-3">
      <div class="bg-white border-gray-200 border w-full grow-1"></div>
      <div class="bg-blue-100 w-14 px-7 rounded-sm"></div>
    </div>

    <div class="flex flex-col pt-1">
      <div class="bg-gray-100 border-gray-200 border -mt-1 w-full h-12 py-6"></div>
      <div class="bg-white border-gray-200 border -mt-1 w-full h-12 py-6"></div>
      <div class="bg-white border-gray-200 border -mt-1 w-full h-12 py-6"></div>
      <div class="bg-white border-gray-200 border -mt-1 w-full h-12 py-6"></div>
      <div class="bg-white border-gray-200 border -mt-1 w-full h-12 py-6"></div>
      <div class="bg-white border-gray-200 border -mt-1 w-full h-12 py-6"></div>
    </div>

  </div>
</template>