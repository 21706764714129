<template>
  <tr class="overflow-visible">
    <EventsTableHeaderCols
      v-for="(tableHeader, i) in tableHeaders" 
      :key="i"
      :props="{
        id: tableHeader.id,
        name: tableHeader.name,
        info: tableHeader.info,
        isFirst: (i == 0) ? true : false,
        isLast: (i == tableHeaders.length-1),
        canSort: tableHeader.canSort
      }"
    />
  </tr>
</template>

<script setup>
import EventsTableHeaderCols from "./EventsTableHeaderCols.vue";
// import { useEventStore } from "@/stores/events";
// const STORE_EVENT = useEventStore();

// let tableHeaders = [
//       { id:"from", name: "From", info: "", canSort: true },
//       { id:"recipients", name: "Recipients", info: "", canSort: false },
//       { id:"subject", name: "Subject", info: "", canSort: true },
//       { id:"timestamp", name: "Timestamp", info: "", canSort: true },
//       { id:"", name: "", info: "", canSort: false },
//     ]

let tableHeaders = [
  { id:"email", name: "Email", info: "", canSort: true },
  { id:"sender", name: "Sender", info: "", canSort: false },
  { id:"recipients", name: "Recipients", info: "", canSort: true },
  { id:"action", name: "Remedy", info: "", canSort: true },
  { id:"", name: "", info: "", canSort: false },
]
</script>