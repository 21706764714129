import { TabsClient, PoliciesClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";
import { Empty } from "@preava/preava-prevent-api-grpc-web-js/messages_pb";
import { SetPolicyRequest, GetPolicyRequest } from "@preava/preava-prevent-api-grpc-web-js/public_pb";
// import { RuleClient } from "prv-api/pkg/web-js/rule_grpc_web_pb";
import { logout, getMetadata, getEndpoint, enumToString, GRPC_ERROR } from "@/modules/v2/utilities";
// import { OrgId, OrganizationB2B, OrganizationItemB2B, ObjectId } from "prv-api/pkg/web-js/ids_pb";
// import { RuleData, RulePayload } from "prv-api/pkg/web-js/rule_pb";
// import { Service } from "prv-api/pkg/web-js/types_pb";

const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}

const PREFIX = '🔮ADAPTER⟹POLICIES';
// const ENDPOINT = getEndpoint();
const ENDPOINT = getApi(location.host);

/**
 * All Allowlist functions
 */
export const getAllowList = async (traceId) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Retreiving the allowlist from the backend...`);

    const metadata = await getMetadata(null, traceId); // null=default request type
    const request = new Empty();
    const gateway = new TabsClient(ENDPOINT, null, null);

    gateway.getAllowList(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
}

export const setAllowList = async (traceId, allowListData) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Updating the allowlist...`);

    const metadata = await getMetadata(null, traceId); // null=default request type
    const request = allowListData;
    const gateway = new TabsClient(ENDPOINT, null, null);

    gateway.setAllowList(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
}

/**
 * All Denylist functions
 */
export const getDenyList = async (traceId) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Retreiving the denylist from the backend...`);

    const metadata = await getMetadata(null, traceId); // null=default request type
    const request = new Empty();
    const gateway = new TabsClient(ENDPOINT, null, null);

    gateway.getDenyList(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
}

export const setDenyList = async (traceId, denyListData) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Updating the denylist...`);

    const metadata = await getMetadata(null, traceId); // null=default request type
    const request = denyListData;
    const gateway = new TabsClient(ENDPOINT, null, null);

    gateway.setDenyList(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
}

/**
 * All Smart Policies functions
 */
export const getSmartPolicies = async (traceId) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Retreiving the smart policies from the backend...`);

    const metadata = await getMetadata(null, traceId); // null=default request type
    const request = new Empty();
    
    const gateway = new PoliciesClient(ENDPOINT, null, null);

    gateway.list(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
}

export const setSmartPolicies = async (traceId, data) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Updating the smart policies...`);

    const metadata = await getMetadata(null, traceId); // null=default request type

    const request = new SetPolicyRequest();
    request.setName(data.name);
    request.setStatus(data.status);
    
    const gateway = new PoliciesClient(ENDPOINT, null, null);

    gateway.set(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
}


export const sanitizePolicy = async (traceId, name) => {
  return new Promise(async (resolve, reject) => {

    console.log(`[${PREFIX}]: Sanitizing the smart policy: `, name);

    const metadata = await getMetadata(null, traceId); // null=default request type

    const request = new GetPolicyRequest();
    request.setName(name);
    
    const gateway = new PoliciesClient(ENDPOINT, null, null);

    gateway.sanitize(request, metadata, (err, res) => {
      let err_data = null;
      if (err) {
        console.error(`[${PREFIX}]: Backend returned and error: `, err);
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout. 
          // Otherwise, return the error to the UI for display
          err_data = {
            type: 'support',
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata['x-request-id'],
            correlationId: metadata['x-trace-id'],
          }
          logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
        } else {
          reject(err);
        }
      } else {
        console.log(`[${PREFIX}]: A response was received from the backend:`, res);
        resolve(res)
      }
    }) // end of gateway
  }) // end of promise
}