<template>
  <TransitionRoot as="template" :show="STORE_MODALS.modalEvent.isShown">
    <Dialog as="div" class="relative z-20" @close="handleClose()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div
          class="
            flex
            items-start
            justify-center
            min-h-full
            p-4
            text-center
            sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="
                relative
                bg-white
                rounded-lg
                px-4
                pt-5
                pb-4
                text-left
                
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:max-w-4xl sm:w-full sm:p-6
              "
            >
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  class="
                    bg-white
                    rounded-md
                    text-gray-400
                    hover:text-gray-500
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                  @click="handleClose()"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="px-4 my-4">
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <ul class="-mb-px flex space-x-8" aria-label="Tabs">
                      <li @click="activeTab = tab.id" v-for="tab in tabs" :key="tab.name" :class="[tab.id == activeTab ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']" :aria-current="tab.id == activeTab ? 'page' : undefined">
                        {{ tab.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                
              <div class="bg-white">
                
                <!-- START OF TAB 1: SUMMARY -->
                <div v-if="activeTab == 1" class="tab-wrapper">

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Timestamp</span>
                        <InfoTooltip message="The date and time (in your browser's timezone) at which Preava Prevent processed the threat analysis." />
                      </h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ `${getDate(STORE_MODALS.modalEvent.event.item.objectId.value)} at ${getTime(STORE_MODALS.modalEvent.event.item.objectId.value)}` }}
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>Subject</h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ (STORE_MODALS.modalEvent.event.emailMessage.meta.subject.value == 'ERROR_SUBJECT_IS_EMPTY' || STORE_MODALS.modalEvent.event.emailMessage.meta.subject.value == 'ERROR_SUBJECT_INPUT_NOT_FOUND_IN_DOM') ? 'undefined' : STORE_MODALS.modalEvent.event.emailMessage.meta.subject.value }}
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>Sender</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--col">
                        <div class="badges-container--col">
                          <div class="badge badge--gray">
                            {{ STORE_MODALS.modalEvent.event.emailMessage.meta.from.value }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>TO Recipients</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row"
                        v-if="STORE_MODALS.modalEvent.event.emailMessage.recipients.to.valuesList.length > 0">
                        <div class="badges-container--row"
                          v-for="(recipients,i) in STORE_MODALS.modalEvent.event.emailMessage.recipients.to.valuesList"
                          :key="i"
                          >
                          <div class="badge badge--blue">
                            {{ recipients.value }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--gray">
                            {{ DEFAULT_NULL_STRING }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>CC Recipients</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row"
                        v-if="STORE_MODALS.modalEvent.event.emailMessage.recipients.cc.valuesList.length > 0">
                        <div class="badges-container--row"
                          v-for="(recipients,i) in STORE_MODALS.modalEvent.event.emailMessage.recipients.cc.valuesList"
                          :key="i"
                          >
                          <div class="badge badge--blue">
                            {{ recipients.value }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--gray">
                            {{ DEFAULT_NULL_STRING }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>BCC Recipients</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row"
                        v-if="STORE_MODALS.modalEvent.event.emailMessage.recipients.bcc.valuesList.length > 0">
                        <div class="badges-container--row"
                          v-for="(recipients,i) in STORE_MODALS.modalEvent.event.emailMessage.recipients.bcc.valuesList"
                          :key="i"
                          >
                          <div class="badge badge--blue">
                            {{ recipients.value }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--gray">
                            {{ DEFAULT_NULL_STRING }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Email Stage</span>
                        <InfoTooltip message="Preflight: before the email was sent. <br/><br/> Inflight: after the email was sent." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--purple">
                            {{ getSourceString(enumToString(Source, STORE_MODALS.modalEvent.event.source)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Analysis Mode</span>
                        <InfoTooltip message="Dry Run: analysis triggered before the send button is pressed. <br/><br/>On Send: analysis triggered right after the send button is pressed.<br/><br/> Testing: analysis triggered on a test email through a testing interface." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--purple">
                            {{ getPreflightModeString(enumToString(PreflightMode, STORE_MODALS.modalEvent.event.emailMessage.options.preflightMode)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Recipient Remedy</span>
                        <InfoTooltip message="The remedy (action) Preava Prevent applied based on the analysis of all email recipients." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getActionBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.recipients.action)]">
                            {{ getActionString(enumToString(Action, STORE_MODALS.modalEvent.event.preflightCheckData.recipients.action)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Recipient Threat Result</span>
                        <InfoTooltip message="The highest risk determined by Preava Prevent across all email recipients. <br/><br/>Null = recipients analysis confidence threshold unmet or programmatically skipped." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getThreatBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.recipients.threat)]">
                            {{ getThreatString(enumToString(Threat, STORE_MODALS.modalEvent.event.preflightCheckData.recipients.threat)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Recipient Threat Details</span>
                        <InfoTooltip message="The email recipient-specific risk determined by Preava Prevent." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--col">
                        <div class="badges-container--col"
                          v-for="(threat, i) in STORE_MODALS.modalEvent.event.preflightCheckData.recipients.threats.tuplesList"
                          :key="i"
                          >
                          <div class="badge-l badge--blue">
                            {{ threat.email.value }}
                          </div>
                          <div :class="['badge-r', getThreatBadgeStyleV2(threat.threat)]">
                            {{ getThreatString(enumToString(Threat, threat.threat)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Content Remedy</span>
                        <InfoTooltip message="The remedy (action) Preava Prevent applied based on the analysis of the email subject, body, and attachment(s)." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getActionBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.content.action)]">
                            {{ getActionString(enumToString(Action, STORE_MODALS.modalEvent.event.preflightCheckData.content.action)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Content Threat Result</span>
                        <InfoTooltip message="The highest risk identified by Preava Prevent within the email content. <br/><br/>Null = content analysis confidence threshold unmet or programmatically skipped." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getThreatBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.content.threat)]">
                            {{ getThreatString(enumToString(Threat, STORE_MODALS.modalEvent.event.preflightCheckData.content.threat)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- END OF TAB 1: SUMMARY -->

                <!-- START OF TAB 3: ANALYSIS OVERVIEW -->
                <div v-if="activeTab == 3" class="tab-wrapper">
                  
                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Email Stage</span>
                        <InfoTooltip message="Preflight: before the email was sent. <br/><br/> Inflight: after the email was sent." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--purple">
                            {{ getSourceString(enumToString(Source, STORE_MODALS.modalEvent.event.source)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Analysis Mode</span>
                        <InfoTooltip message="Dry Run: analysis triggered before the send button is pressed. <br/><br/>On Send: analysis triggered right after the send button is pressed.<br/><br/> Testing: analysis triggered on a test email through a testing interface." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--purple">
                            {{ getPreflightModeString(enumToString(PreflightMode, STORE_MODALS.modalEvent.event.emailMessage.options.preflightMode)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Recipient Remedy</span>
                        <InfoTooltip message="The remedy (action) Preava Prevent applied based on the analysis of all email recipients." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getActionBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.recipients.action)]">
                            {{ getActionString(enumToString(Action, STORE_MODALS.modalEvent.event.preflightCheckData.recipients.action)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Recipient Threat Result</span>
                        <InfoTooltip message="The highest risk determined by Preava Prevent across all email recipients. <br/><br/>Null = recipients analysis confidence threshold unmet or programmatically skipped." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getThreatBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.recipients.threat)]">
                            {{ getThreatString(enumToString(Threat, STORE_MODALS.modalEvent.event.preflightCheckData.recipients.threat)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Recipient Threat Details</span>
                        <InfoTooltip message="The email recipient-specific risk determined by Preava Prevent." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--col">
                        <div class="badges-container--col"
                          v-for="(threat, i) in STORE_MODALS.modalEvent.event.preflightCheckData.recipients.threats.tuplesList"
                          :key="i"
                          >
                          <div class="badge-l badge--blue">
                            {{ threat.email.value }}
                          </div>
                          <div :class="['badge-r', getThreatBadgeStyleV2(threat.threat)]">
                            {{ getThreatString(enumToString(Threat, threat.threat)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Content Remedy</span>
                        <InfoTooltip message="The remedy (action) Preava Prevent applied based on the analysis of the email subject, body, and attachment(s)." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getActionBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.content.action)]">
                            {{ getActionString(enumToString(Action, STORE_MODALS.modalEvent.event.preflightCheckData.content.action)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Content Threat Result</span>
                        <InfoTooltip message="The highest risk identified by Preava Prevent within the email content. <br/><br/>Null = content analysis confidence threshold unmet or programmatically skipped." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getThreatBadgeStyleV2(STORE_MODALS.modalEvent.event.preflightCheckData.content.threat)]">
                            {{ getThreatString(enumToString(Threat, STORE_MODALS.modalEvent.event.preflightCheckData.content.threat)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- This one has backend issues, as per MT. Will hide it if the array returned is empty. -->
                  <div v-if="STORE_MODALS.modalEvent.event.preflightCheckData.content.threatsList.length > 0" class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Content Threat Details</span>
                        <InfoTooltip message="The email content-specific risk(s) identified by Preava Prevent." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--col">
                        <div class="badges-container--col"
                          v-for="(threat, i) in STORE_MODALS.modalEvent.event.preflightCheckData.content.threatsList.length"
                          :key="i"
                          >
                          <div :class="['badge', getThreatBadgeStyleV2(threat.threat)]">
                            {{ getThreatString(enumToString(Threat, threat.threat)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Display this if there is a User Feedback Data -->
                  <div v-if="STORE_MODALS.modalEvent.event.preflightFeedbackData" class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Sender Feedback</span>
                        <InfoTooltip message="Displays 'On Send' if the sender approved one or more risks." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--purple">
                            {{ getSenderFeedbackString(enumToString(Feedback, STORE_MODALS.modalEvent.event.preflightFeedbackData.feedback)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="STORE_MODALS.modalEvent.event.preflightFeedbackData" class="log-wrapper">
                    <div class="log-label">
                      <h4>Approved Email Recipients</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row"
                        v-if="STORE_MODALS.modalEvent.event.preflightFeedbackData.safeRecipients.valuesList.length > 0">
                        <div class="badges-container--row"
                          v-for="(recipient,i) in STORE_MODALS.modalEvent.event.preflightFeedbackData.safeRecipients.valuesList"
                          :key="i"
                          >
                          <div class="badge badge--blue">
                            {{ recipient.value }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--gray">
                            {{ DEFAULT_NULL_STRING }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="STORE_MODALS.modalEvent.event.preflightFeedbackData" class="log-wrapper">
                    <div class="log-label">
                      <h4>Approved Domain Names</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row"
                        v-if="STORE_MODALS.modalEvent.event.preflightFeedbackData.safeDomains.valuesList.length > 0">
                        <div class="badges-container--row"
                          v-for="(domain,i) in STORE_MODALS.modalEvent.event.preflightFeedbackData.safeDomains.valuesList"
                          :key="i"
                          >
                          <div class="badge badge--blue">
                            {{ domain.value }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div class="badge badge--gray">
                            {{ DEFAULT_NULL_STRING }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="STORE_MODALS.modalEvent.event.preflightFeedbackData" class="log-wrapper">
                    <div class="log-label">
                      <h4>Sender Feedback Timestamp</h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ `${formatReceivedTimestamp(STORE_MODALS.modalEvent.event.preflightFeedbackData.receivedAt.seconds)}` }}
                      </code>
                    </div>
                  </div>

                  <!-- If there is no preflight feedback data, this will be displayed -->
                  <div v-if="!STORE_MODALS.modalEvent.event.preflightFeedbackData" class="log-wrapper">
                    <div class="log-label">
                      <h4>Sender Feedback</h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ (STORE_MODALS.modalEvent.event.preflightFeedbackData) ? STORE_MODALS.modalEvent.event.preflightFeedbackData : DEFAULT_NULL_STRING }}
                      </code>
                    </div>
                  </div>
                  
                </div>
                <!-- END OF TAB 3: ANALYSIS OVERVIEW -->


                <!-- START OF TAB 4: RECIPIENT ANALYSIS -->
                <div v-if="activeTab == 4" class="tab-wrapper">

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>Recipient Remedy</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getActionBadgeStyleV2(STORE_MODALS.modalEvent.event.reportData.recipientReport.action)]">
                            {{ getActionString(enumToString(Action, STORE_MODALS.modalEvent.event.reportData.recipientReport.action)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>Recipient Threat Level</h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.reportData.recipientReport.threat * 100 }}%
                      </code>
                    </div>
                  </div>

                  <!-- This section is the per recipient analysis details -->
                  <div v-for="(recipient, i) in STORE_MODALS.modalEvent.event.reportData.recipientReport.recipientsList" :key="i">

                    <div class="log-wrapper--full">
                      <h4>Recipient Analysis Details: <span class="text-gray-800 font-bold">{{ recipient.email.value }}</span></h4>
                    </div>

                    <div class="log-wrapper">
                      <div class="log-label">
                        <h4>Recipient Threat</h4>
                      </div>
                      <div class="log-data--info">
                        <code>
                          {{ recipient.threat * 100 }}%
                        </code>
                      </div>
                    </div>

                    <!-- Rules and Policies Fired section starts here -->
                    <div class="log-wrapper">
                      <div class="log-label">
                        <h4>Rules and Policies Fired</h4>
                      </div>
                      <div class="flex flex-col w-full mx-2 text-sm font-medium space-y-4">
                        <div v-for="(rule, i) in recipient.rulesList" :key="rule.ruleId.value+i" class="py-2 px-3 text-gray-500 bg-gray-100 rounded text-gray-900 break-words">
                          <code>
                            <p>Rule ID: <span class="font-light">{{rule.ruleId.value}}</span> </p>
                            <p>Threat: <span class="font-light">{{ rule.threat * 100 }}%</span></p>
                            <p>Service: <span class="font-light">{{ rule.serviceCompute.name }}, {{ rule.serviceCompute.version }}</span></p>
                            <p class="mt-2">
                              <button @click="toggleAccordion(`${recipient.email.value}--${rule.ruleId.value}`)" class="text-blue-600 hover:text-blue-500">
                                {{ openAccordions.includes(`${recipient.email.value}--${rule.ruleId.value}`) ? 'Hide' : 'Show' }} Debug Details
                              </button>
                            </p>
                            <p v-if="openAccordions.includes(`${recipient.email.value}--${rule.ruleId.value}`)" class="text-sm mt-4 font-light">
                              <ul>
                                <li v-for="(item, i) in rule.itemsList" :key="i">
                                  Threat [{{ item.threat * 100 }}%]
                                  <ul class="ml-8">
                                    <li v-for="(debug, i) in item.debugList" :key="i" class="list-disc">
                                      {{ debug }}
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </p>
                          </code>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <!-- END OF TAB 4: RECIPIENT ANALYSIS -->

                <!-- START OF TAB 5: CONTENT ANALYSIS -->
                <div v-if="activeTab == 5" class="px-4 py-0 sm:p-0">

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>Content Remedy</h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--row">
                        <div class="badges-container--row">
                          <div :class="['badge', getActionBadgeStyleV2(STORE_MODALS.modalEvent.event.reportData.contentReport.action)]">
                            {{ getActionString(enumToString(Action, STORE_MODALS.modalEvent.event.reportData.contentReport.action)) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>Content Threat Level</h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.reportData.contentReport.threat * 100 }}%
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>Rules and Policies Fired</h4>
                    </div>
                    <div class="flex flex-col w-full mx-2 text-sm font-medium space-y-4">
                      <div v-for="(rule, i) in STORE_MODALS.modalEvent.event.reportData.contentReport.rulesList" :key="rule.ruleId.value+i" class="py-2 px-3 text-gray-500 bg-gray-100 rounded text-gray-900 break-words">
                        <code>
                          <p>Rule ID: <span class="font-light">{{rule.ruleId.value}}</span> </p>
                          <p>Threat: <span class="font-light">{{ rule.threat * 100 }}%</span></p>
                          <p>Service: <span class="font-light">{{ rule.serviceCompute.name }}, {{ rule.serviceCompute.version }}</span></p>
                          <p class="mt-2">
                            <button @click="toggleAccordion(`content--${rule.ruleId.value}`)" class="text-blue-600 hover:text-blue-500">
                              {{ openAccordions.includes(`content--${rule.ruleId.value}`) ? 'Hide' : 'Show' }} Debug Details
                            </button>
                          </p>
                          <p v-if="openAccordions.includes(`content--${rule.ruleId.value}`)" class="text-sm mt-4 font-light">
                            <ul>
                              <li v-for="(item, i) in rule.itemsList" :key="i">
                                Threat [{{ item.threat * 100 }}%]
                                <ul class="ml-8">
                                  <li v-for="(debug, i) in item.debugList" :key="i" class="list-disc">
                                    {{ debug }}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </p>
                        </code>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END OF TAB 5: CONTENT ANALYSIS -->


                <!-- START OF TAB 5: DIAGNOSTICS -->
                <div v-if="activeTab == 2" class="tab-wrapper">

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>User Email Address</span>
                        <InfoTooltip message="The email address of the user signed into Preava Prevent." />
                      </h4>
                    </div>
                    <div class="log-data">
                      <div class="badges-wrapper--col">
                        <div class="badges-container--col">
                          <div class="badge badge--gray">
                            {{ STORE_MODALS.modalEvent.user.email.value }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Message ID</span>
                        <InfoTooltip message="An email message identifier generated by the email provider." />
                      </h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.emailMessage.meta.messageId.value }}
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Thread ID</span>
                        <InfoTooltip message="An email thread identifier generated by the email provider." />
                      </h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.emailMessage.meta.threadId.value }}
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Transaction ID</span>
                        <InfoTooltip message="An threat analysis identifier generated by Preava." />
                      </h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.transactionId.value }}
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Object ID</span>
                        <InfoTooltip message="A database log object identifier generated by Preava." />
                      </h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.item.objectId.value }}
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Endpoint Name and Version</span>
                        <InfoTooltip message="The user's Preava Prevent client, and its version." />
                      </h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.emailMessage.options.endpointInfo.name.value }}, Version  
                        {{ (STORE_MODALS.modalEvent.event.emailMessage.options.endpointInfo.version) ? STORE_MODALS.modalEvent.event.emailMessage.options.endpointInfo.version.value : 'undefined' }}
                      </code>
                    </div>
                  </div>

                  <div class="log-wrapper">
                    <div class="log-label">
                      <h4>
                        <span>Platform Name and Version</span>
                        <InfoTooltip message="The user's email platform (client), and its version." />
                      </h4>
                    </div>
                    <div class="log-data--info">
                      <code>
                        {{ STORE_MODALS.modalEvent.event.emailMessage.options.platformInfo.name.value }}, Version 
                        {{ (STORE_MODALS.modalEvent.event.emailMessage.options.platformInfo.version.value) ? STORE_MODALS.modalEvent.event.emailMessage.options.platformInfo.version.value : 'undefined' }}
                      </code>
                    </div>
                  </div>

                </div>
                <!-- END OF TAB 5: DIAGNOSTICS -->

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { Policy, Source, PreflightMode, Action, Threat, Risk, Activity, Role, Seat, Feedback } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { enumToString } from "@/utilities/helpers";
import InfoTooltip from "@/components/tooltip/InfoTooltip";
import { useModalsStore } from "@/stores/modals";
const STORE_MODALS = useModalsStore();


import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";


const STATIC_MAP = new Map([
  ["60c276aeff3fa6494b12be30", "PrefabRiskyEmail"],
  ["60c276aeff3fa6494b12be31", "PrefabRiskyDomain"],
  ["60c276aeff3fa6494b12be34", "CustomDeriskEmail"],
  ["60c276aeff3fa6494b12be35", "CustomDeriskDomain"],
  ["60c276aeff3fa6494b12be36", "AutoDeriskEmail"],
  ["60c276aeff3fa6494b12be37", "AutoDeriskDomain"],
]);



export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    InfoTooltip
  },
  setup() {
    return {
      STORE_MODALS: STORE_MODALS,
      Source,
      PreflightMode,
      Policy,
      Action, 
      Risk,
      Threat,
      enumToString,
      STATIC_MAP,
      Activity, 
      Role, 
      Seat,
      Feedback,
    };
  },
  data(){
    return {
      openAccordions: [],
      tabs: [
        // { id: 1, name: 'Email Message Overview' },
        // { id: 2, name: 'Diagnostic Information' },
        // { id: 3, name: 'Analysis Overview' },
        // { id: 4, name: 'Recipient Threat Analysis Details' },
        // { id: 5, name: 'Contents Threat Analysis Details' },
        { id: 1, name: 'Summary' },
        
        { id: 3, name: 'Analysis Overview' },
        { id: 4, name: 'Recipient Analysis' },
        { id: 5, name: 'Content Analysis' },
        { id: 2, name: 'Diagnostics' },
      ],
      activeTab: ref(1),
      DEFAULT_NULL_STRING: 'undefined'
    }
  },
  methods: {
    handleClose() {
      STORE_MODALS.modalEvent.isShown = false;
    },
    getDate(id) {
      let timestamp = id.toString().substring(0, 8);
      return moment.unix(parseInt(timestamp, 16)).format('YYYY-MM-DD')
    },getTime(id) {
      let timestamp = id.toString().substring(0, 8);
      return moment.unix(parseInt(timestamp, 16)).format('hh:mm A')
    },
    toDateTime(secs) {
      var t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(secs);
      return t;
    },
    formatReceivedTimestamp(seconds){
      let parsedMomentDate = moment.unix(parseInt(seconds)).format('YYYY-MM-DD')
      let parsedMomentTime = moment.unix(parseInt(seconds)).format('hh:mm A')
      return `${parsedMomentDate} at ${parsedMomentTime}`
    },
    getActionBadgeStyle(_action){
      switch (_action) {
        case Action.ACTION_ALLOW:
          return 'bg-green-100 text-green-800'
        case Action.ACTION_WARN:
          return 'bg-yellow-100 text-yellow-800'
        case Action.ACTION_DENY:
          return 'bg-red-100 text-red-800'
        case Action.ACTION_SKIP:
          return 'bg-blue-100 text-blue-800'
        default:
          return 'bg-gray-100 text-gray-800'
      }
    },
    getActionBadgeStyleV2(_action){
      switch (_action) {
        case Action.ACTION_ALLOW:
          return 'badge--green'
        case Action.ACTION_WARN:
          return 'badge--yellow'
        case Action.ACTION_DENY:
          return 'badge--red'
        case Action.ACTION_SKIP:
          return 'badge--blue'
        default:
          return 'badge--gray'
      }
    },
    getThreatBadgeStyle(threat){
      switch (threat) {
        case Threat.THREAT_ONE:
          return 'bg-red-500 text-white'
        case Threat.THREAT_HIGH:
        return 'bg-red-100 text-red-800'
        case Threat.THREAT_MEDIUM:
          return 'bg-yellow-100 text-yellow-800'
        case Threat.THREAT_LOW:
        return 'bg-green-300 text-green-800'
        case Threat.THREAT_ZERO:
          return 'bg-green-100 text-green-800'
        case Threat.THREAT_NAN:
        return 'bg-gray-100 text-gray-800'
        case Threat.THREAT_NIL:
          return 'bg-blue-100 text-blue-800'
        default:
          return 'bg-gray-100 text-gray-800'
      }
    },
    getThreatBadgeStyleV2(threat){
      switch (threat) {
        case Threat.THREAT_ONE:
          return 'badge--red-one'
        case Threat.THREAT_HIGH:
        return 'badge--red-high'
        case Threat.THREAT_MEDIUM:
          return 'badge--yellow'
        case Threat.THREAT_LOW:
        return 'badge--green-low'
        case Threat.THREAT_ZERO:
          return 'badge--green'
        case Threat.THREAT_NAN:
        return 'badge--gray'
        case Threat.THREAT_NIL:
          return 'badge--blue'
        default:
          return 'badge--green'
      }
    },
    toggleAccordion(_accordionId){
      if(this.openAccordions.includes(_accordionId)){
        let i = this.openAccordions.findIndex(e => e ==_accordionId)
        this.openAccordions.splice(i, 1)
      }else{
        this.openAccordions.push(_accordionId)
      }
    },
    getThreatString(threat){
      var threatArr = threat.split("_");
      var threatString = threatArr[1].charAt(0).toUpperCase() + threatArr[1].slice(1).toLowerCase();
      return (threatString == 'Nan') ? 'Null' : threatString;
    },
    getActionString(action){
      var actionArr = action.split("_");
      var actionString = actionArr[1].charAt(0).toUpperCase() + actionArr[1].slice(1).toLowerCase();
      return actionString;
    },
    getSourceString(source){
      return source.charAt(0).toUpperCase() + source.slice(1).toLowerCase();
    },
    getPreflightModeString(preflightMode){
      var preflightModeArr = preflightMode.split("_");
      preflightModeArr.splice(0, 2)

      let _arr = []
      preflightModeArr.forEach( (word, index) => {
        let _word =  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        _arr.push(_word)
      });
      return _arr.join(" ")
    },
    getSenderFeedbackString(feedback){
    var feedbackArr = feedback.split("_");
    feedbackArr.splice(0, 2)

      let _arr = []
      feedbackArr.forEach( (word, index) => {
        let _word =  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        _arr.push(_word)
      });
      return _arr.join(" ")
    }
  }

};
</script>
<style>

.tab-wrapper{
  @apply px-4 py-0 sm:px-2 space-y-2
}

.log-wrapper{
  @apply flex px-4 py-2
}

.log-wrapper--full{
  @apply flex px-4 py-2 w-full
}

.log-label{
  @apply w-1/3 flex-none
}

.log-data{
  @apply w-2/3 px-2
}

.log-data--info{
  @apply w-2/3 mx-2 py-2 px-3 text-sm font-medium text-gray-500 bg-gray-100 rounded text-gray-900 break-words
}

.log-label h4{
  @apply text-sm font-medium text-gray-500 flex items-center mt-1
}

.badges-wrapper--col{
  @apply flex flex-col space-y-2 break-words 
}

.badges-container--col{
  @apply flex inline-flex
}

.badges-wrapper--row{
  @apply flex space-x-2 flex-wrap -ml-2 -mb-2
}

.badges-container--row{
  @apply flex ml-2 mb-2
}

.badge{
  @apply px-3 py-0.5 text-sm rounded-full 
}

.badge-l{
  @apply pl-3 pr-2 py-0.5 text-sm rounded-l-full border-r border-blue-500
}

.badge-r{
  @apply pr-3 pl-2 py-0.5 text-sm rounded-r-full
}

.badge--blue{
  @apply bg-blue-200 text-blue-700 
}

.badge--gray{
  @apply bg-gray-200 text-gray-700 
}

.badge--red{
  @apply bg-red-200 text-red-700 
}

.badge--yellow{
  @apply bg-yellow-200 text-yellow-700 
}

.badge--green{
  @apply bg-green-200 text-green-700 
}

.badge--purple{
  @apply bg-purple-200 text-purple-700 
}

/** threat badges */

.badge--red-one{
  @apply bg-red-500 text-white 
}

.badge--red-high{
  @apply bg-red-100 text-red-800
}

.badge--green-low{
  @apply bg-green-300 text-green-800
}

</style>