<template>
  <tr>
    <!--  -->

    <td
      class="
        w-1/5
        whitespace-nowrap
        py-4
        pl-4
        pr-4
        font-normal
        text-gray-900
        sm:pl-6
      "
    >
      <div class="flex items-center">
        <div class="w-full">
          <div class="font-normal text-sm text-gray-900 truncate">
            <!-- @TODO: Fetch this via GetUser API -->
            <!-- <span class="text-ellipsis">{{ '<NAME_HERE>' }}</span> -->
          </div>
          <div
            class="text-sm font-normal text-gray-700 shrink max-w-60 truncate"
          >
          <p class="font-normal text-sm w-full text-gray-700 truncate">
            <span class="font-bold">Subject:</span> {{ event.emailMessage.meta.subject.value }}
          </p>
          <p class="font-normal text-sm w-full text-gray-500 truncate">
            <!-- via {{ event.emailMessage.options.endpointInfo.name.value.replace("Preava Prevent", "") }} -->
            <span class="text-ellipsis">Sent on {{ getDate(event.item.objectId.value) }} at {{ getTime(event.item.objectId.value) }} </span>
          </p>
          </div>
        </div>
      </div>
    </td>

    <!--  -->

    <td class="w-1/5 whitespace-nowrap p-4 text-sm text-gray-500 space-y-1">
      <span class="text-ellipsis">{{ event.emailMessage.meta.from.value }}</span>
    </td>

    <!--  -->

    <td class="py-4 pl-4 pr-3 text-sm overflow-wrap">
      <div class="items-center space-x-1 truncate flex">
        <span class="text-sm text-gray-500 uppercase font-semibold">To:</span>
        <span class="text-sm text-gray-500 shrink max-w-60 truncate">{{
          event.emailMessage.recipients.to.valuesList.length > 0
            ? event.emailMessage.recipients.to.valuesList[0].value
            : "--"
        }}</span>
        <span
          v-if="event.emailMessage.recipients.to.valuesList.length > 1"
          class="text-sm text-gray-800 font-semibold"
          >+{{ event.emailMessage.recipients.to.valuesList.length - 1 }} more</span
        >
      </div>
      <div class="items-center space-x-1 truncate flex">
        <span class="text-sm text-gray-500 uppercase font-semibold">Cc:</span>
        <span class="text-sm text-gray-500 shrink max-w-60 truncate">{{
          event.emailMessage.recipients.cc.valuesList.length > 0
            ? event.emailMessage.recipients.cc.valuesList[0].value
            : "--"
        }}</span>
        <span
          v-if="event.emailMessage.recipients.cc.valuesList.length > 1"
          class="text-sm text-gray-500"
          >+{{ event.emailMessage.recipients.cc.valuesList.length - 1 }} more</span
        >
      </div>
      <div class="items-center space-x-1 truncate flex">
        <span class="text-sm text-gray-500 uppercase font-semibold">Bcc:</span>
        <span class="text-sm text-gray-500 shrink max-w-60 truncate">{{
          event.emailMessage.recipients.bcc.valuesList.length > 0
            ? event.emailMessage.recipients.bcc.valuesList[0].value
            : "--"
        }}</span>
        <span
          v-if="event.emailMessage.recipients.bcc.valuesList.length > 1"
          class="text-sm text-gray-500"
          >+{{ event.emailMessage.recipients.bcc.valuesList.length - 1 }} more</span
        >
      </div>
    </td>

    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
      <div class="text-gray-500 text-xs truncate flex flex-col space-y-1">
        <p class="text-ellipsis flex items-center space-x-1">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </span>
          <div 
            :class="
            [getActionBadgeStyle(event.preflightCheckData.recipients.action),
            'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-normal max-w-full truncate']">
            <div class="truncate">
              <span class="text-ellipsis">{{ getActionName(event.preflightCheckData.recipients.action) }}</span>
            </div>
          </div>
          <!-- <div 
            :class="
            [getThreatBadgeStyle(event.preflightCheckData.recipients.threat),
            'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-normal max-w-full truncate']">
            <div class="truncate">
              <span class="text-ellipsis">{{ getThreatName(event.preflightCheckData.recipients.threat) }}</span>
            </div>
          </div> -->
        </p>
        <p class="text-ellipsis flex items-center space-x-1">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
            </svg>
          </span>
          <div 
            :class="
            [getActionBadgeStyle(event.preflightCheckData.content.action),
            'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-normal max-w-full truncate']">
            <div class="truncate">
              <span class="text-ellipsis">{{ getActionName(event.preflightCheckData.content.action) }}</span>
            </div>
          </div>
          <!-- <div 
            :class="
            [getThreatBadgeStyle(event.preflightCheckData.content.threat),
            'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-normal max-w-full truncate']">
            <div class="truncate">
              <span class="text-ellipsis">{{ getThreatName(event.preflightCheckData.content.threat) }}</span>
            </div>
          </div> -->
        </p>
      </div>
    </td>

    <!-- <td class="w-1/5 whitespace-nowrap p-4 text-sm text-gray-500 space-y-1">
      <div class="items-center space-x-1 truncate">
        <span class="inline-flex items-center bg-purple-100 px-2.5 py-0.5 text-xs font-medium text-purple-800">
          {{ enumToString(Source, event.source) }}
        </span>
      </div>
    </td> -->

    <td class="relative whitespace-nowrap text-center text-sm font-normal">
      <div class="items-center space-x-1 truncate hidden xl:block">
        <button
          @click="handleAction(event)"
          class="text-blue-600 hover:text-blue-900 text-ellipsis mr-14"
        >
          View Details
        </button>
      </div>
      <div class="items-center space-x-1 truncate block xl:hidden">
        <button
          @click="handleAction(event)"
          class="text-blue-600 hover:text-blue-900 text-ellipsis"
        >
          <DocumentTextIcon class="w-8 h-4" />
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { Source, Action as ACTION, Threat as THREAT } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { enumToString } from "@/utilities/helpers";
// import { useEventStore } from "@/stores/events";
// import { useSessionStore } from "@/stores/session";

import { useSessionStore } from "@/stores/sessionv2";

import { useModalsStore } from "@/stores/modals";

import { SelectorIcon, DocumentTextIcon } from "@heroicons/vue/outline";

import moment from 'moment'


// const STORE_EVENT = useEventStore();
const STORE_MODALS = useModalsStore();
const STORE_SESSION = useSessionStore();


function getDate(id) {
  let timestamp = id.toString().substring(0, 8);
  return moment.unix(parseInt(timestamp, 16)).format('YYYY-MM-DD')
}


function getTime(id) {
  let timestamp = id.toString().substring(0, 8);
  return moment.unix(parseInt(timestamp, 16)).format('hh:mm:ss')
}


export default {
  components: {
    SelectorIcon, DocumentTextIcon
  },
  props: ["event"],
  setup() {
    return {
      getDate: getDate,
      getTime: getTime,
      // STORE_EVENT: STORE_EVENT,
      Source,
      enumToString,
      ACTION: ACTION,
      THREAT: THREAT
    };
  },
  methods: {
    async handleAction(event){
      console.log("Opening Event Modal info...")
      console.log(event)
      let user = await STORE_SESSION.fetchUser({ userId: event.item.userId.value});
      
      // STORE_SESSION.notification.isShown = false
      STORE_MODALS.modalEvent.isShown = true
      STORE_MODALS.modalEvent.event = event 
      STORE_MODALS.modalEvent.user = user.user
      console.log(STORE_MODALS.modalEvent.user)
      
    },

    findHighestThreat(threat1, threat2){
      
      // enum Threat {
      //   THREAT_NIL = 0;
      //   THREAT_NAN = 1;
      //   THREAT_ZERO = 2;
      //   THREAT_LOW = 3;
      //   THREAT_MEDIUM = 4;
      //   THREAT_HIGH = 5;
      //   THREAT_ONE = 6;
      // }
      return (threat1 > threat2) ? threat1 : threat2
    },

    fintHighestAction(action1, action2){
      // enum Action {
      //   ACTION_ALLOW = 0;
      //   ACTION_WARN = 1;
      //   ACTION_DENY = 2;
      //   ACTION_SKIP = 3;
      // }

      if(action1 == ACTION.ACTION_DENY || action2 == ACTION.ACTION_DENY){
        return ACTION.ACTION_DENY
      }else if(action1 == ACTION.ACTION_WARN || action2 == ACTION.ACTION_WARN){
        return ACTION.ACTION_WARN
      }else if(action1 == ACTION.ACTION_ALLOW || action2 == ACTION.ACTION_ALLOW){
        return ACTION.ACTION_ALLOW
      }else if(action1 == ACTION.ACTION_SKIP || action2 == ACTION.ACTION_SKIP){
        return ACTION.ACTION_SKIP
      }
    },
    getActionName(action){
      var actionArr = enumToString(ACTION, action).split("_");
      var actionString = actionArr[1].charAt(0).toUpperCase() + actionArr[1].slice(1).toLowerCase();
      return actionString;
    },
    // getActionName(action){
    //   let str = enumToString(ACTION, action).replace("ACTION_", "").toLowerCase()
    //   const str2 = str.charAt(0).toUpperCase() + str.slice(1)
    //   return `${str2}`
    // },
    getActionBadgeStyle(action){
      // enum Action {
      //   ACTION_ALLOW = 0;
      //   ACTION_WARN = 1;
      //   ACTION_DENY = 2;
      //   ACTION_SKIP = 3;
      // }
      switch (action) {
        case ACTION.ACTION_ALLOW:
          return 'bg-green-100 text-green-800'
        case ACTION.ACTION_WARN:
          return 'bg-yellow-100 text-yellow-800'
        case ACTION.ACTION_DENY:
          return 'bg-red-100 text-red-800'
        case ACTION.ACTION_SKIP:
          return 'bg-blue-100 text-blue-800'
        default:
          return 'bg-gray-100 text-gray-800'
      }
      
    },
    getThreatName(threat){
      let str = enumToString(THREAT, threat).replace("THREAT_", "").toLowerCase()
      const str2 = str.charAt(0).toUpperCase() + str.slice(1)
      return `Threat; ${str2}`
    },
    getThreatBadgeStyle(threat){
      // enum Threat {
      //   THREAT_NIL = 0;
      //   THREAT_NAN = 1;
      //   THREAT_ZERO = 2;
      //   THREAT_LOW = 3;
      //   THREAT_MEDIUM = 4;
      //   THREAT_HIGH = 5;
      //   THREAT_ONE = 6;
      // }

      switch (threat) {
        case THREAT.THREAT_ONE:
          return 'bg-red-500 text-white'
        case THREAT.THREAT_HIGH:
        return 'bg-red-100 text-red-800'
        case THREAT.THREAT_MEDIUM:
          return 'bg-yellow-100 text-yellow-800'
        case THREAT.THREAT_LOW:
        return 'bg-green-300 text-green-800'
        case THREAT.THREAT_ZERO:
          return 'bg-green-100 text-green-800'
        case THREAT.THREAT_NAN:
        return 'bg-green-100 text-green-800'
        case THREAT.THREAT_NIL:
          return 'bg-blue-100 text-blue-800'
        default:
          return 'bg-gray-100 text-gray-800'
      }
    },
    
  }
};
</script>
