import { defineStore } from 'pinia'
import { v4 } from "uuid";

export const useModalsStore = defineStore("modals", {
  state: () => ({
    eventsSearch: {
      searchQuery: '',
      searchBy: '' // @TODO: Convert this to ENUM
    },
    notification: {},
    toast: {},
    // Users Page
    modalUninvite: {
      isShown: false,
      user: null,
    },
    modalInvite: {
      isShown: false,
      users: null,
    },
    // Event Page
    modalEvent: {
      isShown: false,
      event: null,
      user: null,
    },
    // sometimes we don't want to show any status/notifications while 
    // we do background refresh of the user list. We set this 
    // isDiscreet flag to true if needed.
    isDiscreet: false,  
    // if the UI is waiting, set this flag
    isWaiting: true
  }),
  actions: {
    // async updateTraceId(payload) {
    //   this.traceId = payload
    // },
    async setNotification(payload) {
      this.notification = payload
    },
    async setToast(payload) {
      this.toast = payload
    },
  },
})