import { defineStore } from 'pinia'
import { v4 } from "uuid";
import ROUTER from '@/router';
import { GRPC_ERROR } from "@/utilities/enums";
import { getMetadata, enumToString } from "@/utilities/helpers";

// import { getApi } from "prv-js-common/endpoints";
// const ENDPOINT = location.host.includes(":8080")
//   ? process.env.VUE_APP_TEST_ENDPOINT
//   : getApi(location.host);

// preava protos
import {
  SearchLogFilters,
  SearchLogFieldsData
} from "@preava/preava-prevent-api-grpc-web-js/public_pb";
import {
  String,
  Limit,
  Date,
  Order as ORDER,
} from "@preava/preava-prevent-api-grpc-web-js/filters_pb";
import {
  Role as ROLE,
  Seat as SEAT,
  Activity as ACTIVITY,
} from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { AdminClient, LogClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";
import { UserId } from "@preava/preava-prevent-api-grpc-web-js/ids_pb";
import { Empty } from "@preava/preava-prevent-api-grpc-web-js/messages_pb";

// import { AdminClient } from "@preava/preava-prevent-api-grpc-web-js/gateway_grpc_web_pb";

import { Adapter } from '../utilities/adapter'

const getApi = (host) => {
  let modifiedHost = host.replace('admin', 'api')
  return (location.host.includes(":8080")) ? process.env.VUE_APP_TEST_ENDPOINT : `https://${modifiedHost}`;
}

const ADAPTER = new Adapter();
const ENDPOINT = getApi(location.host);

export const useSessionStore = defineStore("sessionv2", {
  state: () => ({
    traceId: null,
    isAdmin: false,
    isWaiting: true,
    filter: {},
    events: [],
    count: 0,
  }),
  actions: {
    async initializeSession() {
      this.isWaiting = true;
      this.createNewTraceId();
      await this.checkIfAdmin();
    },
    createNewTraceId() {
      this.traceId = v4()
    },
    async checkIfAdmin() {
      let result = await ADAPTER.activate(this.traceId)
      console.log(result)
      if (result.role === ROLE.ROLE_ADMIN) {
        this.isAdmin = true;
      } else if (result.role === ROLE.ROLE_USER) {
        ROUTER.push({ path: '/integrations' })
      }
    },
    async fetchEvents(
      { dateStart,
        dateEnd,
        subject,
        from,
        limit,
        to,
        cc,
        bcc,
        order
     }
    ) {

      this.isWaiting = true;

      const metadata = await getMetadata(null, this.traceId);
      const request = new SearchLogFilters();
      const gateway = new LogClient(ENDPOINT, null, null);

      let date = new Date();
      date.setFrom(new Timestamp().setSeconds(dateStart));
      date.setTo(new Timestamp().setSeconds(dateEnd));

      let data = new SearchLogFieldsData();
      if (from) data.setFrom(new String().setValue(from));
      if (subject) data.setSubject(new String().setValue(subject));
      if (to) data.setTo(new String().setValue(to));
      if (cc) data.setCc(new String().setValue(cc));
      if (bcc) data.setBcc(new String().setValue(bcc));
      // data.setThreadId(new String().setValue('some-thread-id'));
      // data.setMessageId(new String().setValue(''));
      // data.setTransactionId(new String().setValue(''));
      
      request.setLimit(new Limit().setValue(limit));
      request.setOrder(order);
      request.setDate(date);
      request.setData(data);

      console.log('-----');
      console.log({ request });

      // const stream = gateway.searchTelemetry(request, metadata);
      const stream = gateway.search(request, metadata);

      var _telemetries = [];
      stream.on("data", function (res) {
        let _telemetry = res.toObject().log;
        console.log('📡 LOG: ', _telemetry)
        _telemetries.push(_telemetry);
      });

      stream.on("error", function (err) {
        let err_data = null;
        console.error(`A telemetry stream error was returned:`, err);
        
        if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
          // If user is unauthenticated, always logout.
          // Otherwise, return the error to the UI for display
          err_data = {
            type: "support",
            message: err.message,
            details: err.details,
            code: err.code,
            status: enumToString(GRPC_ERROR, err.code),
            requestId: metadata["x-request-id"],
            correlationId: metadata["x-trace-id"],
          };
          logout({
            returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status
              }&requestId=${encodeURI(
                err_data.requestId
              )}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type
              }&code=${err_data.code}&details=${encodeURI(
                err_data.details
              )}&message=${encodeURI(err_data.message)}`,
          });
        } 
        else if (err.code == GRPC_ERROR.DATA_LOSS) {
          console.log("Report API returned a GRPC.DATA_LOSS error")
          // Do nothing
        }
        else {
          stream.cancel();
        }
      });

      stream.on("end", () => {
        stream.cancel();
        // resolve(_telemetries);
        this.updateEvents(_telemetries)
      });

    },
    async fetchUser({ userId }) {

      this.isWaiting = true;

      return new Promise(async (resolve, reject) => {

        const metadata = await getMetadata(null, this.traceId);
        
        const request = new UserId();
        request.setValue(userId);
        
        const gateway = new AdminClient(ENDPOINT, null, null);
        gateway.getUser(request, metadata, (err, res) => {
          let err_data = null;
          if (err) {
            console.error(`Error occurred while retreiving user data`);
            console.error(err);
            if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
              // If user is unauthenticated, always logout. 
              // Otherwise, return the error to the UI for display
              err_data = {
                type: 'support',
                message: err.message,
                details: err.details,
                code: err.code,
                status: enumToString(GRPC_ERROR, err.code),
                requestId: metadata['x-request-id'],
                correlationId: metadata['x-trace-id'],
              }
              logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            } else {
              reject(err);
            }
          } else {
            console.log(`A response was received from the backend:`, res);
            // conditional here
            
            resolve(res.toObject());
          }
        })

        this.isWaiting = false;

      })

    },

    async countLogs() {

      this.isWaiting = true;
      console.log("Counting...")

      return new Promise(async (resolve, reject) => {

        const metadata = await getMetadata(null, this.traceId);
        
        const request = new Empty();
        
        // request.setValue({});
        
        const gateway = new LogClient(ENDPOINT, null, null);

        gateway.count(request, metadata, (err, res) => {
          let err_data = null;
          if (err) {
            console.error(`Error occurred while counting logs data`);
            console.error(err);
            if (err.code == GRPC_ERROR.UNAUTHENTICATED) {
              // If user is unauthenticated, always logout. 
              // Otherwise, return the error to the UI for display
              err_data = {
                type: 'support',
                message: err.message,
                details: err.details,
                code: err.code,
                status: enumToString(GRPC_ERROR, err.code),
                requestId: metadata['x-request-id'],
                correlationId: metadata['x-trace-id'],
              }
              logout({ returnTo: `${location.protocol}//${location.host}/error?status=${err_data.status}&requestId=${encodeURI(err_data.requestId)}&correlationId=${encodeURI(err_data.correlationId)}&type=${err_data.type}&code=${err_data.code}&details=${encodeURI(err_data.details)}&message=${encodeURI(err_data.message)}` });
            } else {
              reject(err);
            }
          } else {
            console.log(`A response was received from the backend:`, res);
            // conditional here
            // this.count = res.toObject();
            this.count = res.toObject().items;
            console.log('count', this.count)
            resolve(true)
          }
        })

        this.isWaiting = false;

      })

    },
    updateFilter(payload) {
      this.filter = { ...this.filter, ...payload };
    },
    updateEvents(payload) {
      // this.events = [ ...this.events, ...payload ];
      this.events = payload;
      this.isWaiting = false;
    },
  },
})