import { defineStore } from "pinia";
import { GRPC_ERROR, enumToString } from "@/utilities";
import { useSessionStore } from "@/modules/v2/store";
import { ref } from "vue";
import { 
    getAllowList, setAllowList, 
    getDenyList, setDenyList, 
    getCustomDlp, setCustomDlp,
    getSmartPolicies, setSmartPolicies, sanitizePolicy
} from "@/modules/v2/adapter";

const PREFIX = '💽STORE⟹POLICIES';

const SESSION_STORE = useSessionStore();

/**
 * TODO:
 * This store is still called "policies" which is outdated. 
 * Please note that this is subject to refactoring.
 */
export const usePoliciesStore = defineStore('v2_policies', () => {

    const allowlist = ref(null);
    const denylist = ref(null);
    const smartpolicies = ref(null);
    
    const fetchAllowList = async (traceId) => {
        console.log(`[${PREFIX}]: Preava Prevent will now fetch the allowlist from the backend...`);
        allowlist.value = await getAllowList(traceId);
        return true;
    }

    const pushAllowlistChanges = async (traceId) => {
        console.log(`[${PREFIX}]: Preava Prevent will now push the allowlist changes to the backend...`);
        let result = await setAllowList(traceId, allowlist.value);
        if(!result){  return false };
        return true;
    }

    const fetchDenyList = async (traceId) => {
        console.log(`[${PREFIX}]: Preava Prevent will now fetch the denylist from the backend...`);
        denylist.value = await getDenyList(traceId);
        return true;
    }

    const pushDenylistChanges = async (traceId) => {
        console.log(`[${PREFIX}]: Preava Prevent will now push the denylist changes to the backend...`);
        let result = await setDenyList(traceId, denylist.value);
        if(!result){  return false };
        return true;
    }

    const fetchSmartPolicies = async (traceId) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(`[${PREFIX}]: Preava Prevent will now fetch the smart policies from the backend...`);
                smartpolicies.value = await getSmartPolicies(traceId);

                resolve(true)
            } catch (error) {
                reject(error)
            }
        })
    }

    const pushSmartPoliciesChanges = async (traceId, data) => {
        console.log(`[${PREFIX}]: Preava Prevent will now push the smart policies changes to the backend...`);
        try {
            await setSmartPolicies(traceId, data);
            return true;
        } catch (error) {
            SESSION_STORE.errorMessage = `GRPC Error Code ${error.code}: ${enumToString(GRPC_ERROR, error.code)}, Message: ${error.message}`;
            SESSION_STORE.showErrorMessage = true;
            return false;
        }
    }

    const pushSanitizePolicy = async (traceId, name) => {
        console.log(`[${PREFIX}]: Preava Prevent will now sanitize the smart policy to the backend...`);
        try {
            await sanitizePolicy(traceId, name);
            return true;
        } catch (error) {
            SESSION_STORE.errorMessage = `GRPC Error Code ${error.code}: ${enumToString(GRPC_ERROR, error.code)}, Message: ${error.message}`;
            SESSION_STORE.showErrorMessage = true;
            return false;
        }
    }

    return { 
        allowlist, denylist, smartpolicies,
        fetchAllowList, pushAllowlistChanges,
        fetchDenyList, pushDenylistChanges,
        fetchSmartPolicies, pushSmartPoliciesChanges,
        pushSanitizePolicy
        
    }
})