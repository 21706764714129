<template>
  <!-- Email/Domain Form Input -->
  <section>
    <div>
      <div class="relative flex space-x-3 items-center">
        <input
          v-model="formInput"
          @keyup.,="onSeparator"
          @keyup.enter="onSubmit"
          @input="onUpdate"
          type="text"
          name="prvemail"
          id="prvemail"
          :class="[
            errorMessage
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
              : 'text-gray-900 placeholder-gray-300 focus:border-gray-500 focus:ring-gray-500 border-gray-300',
            'block w-full pr-10 focus:outline-none sm:text-sm',
          ]"
          placeholder="separate multiple entries with a comma (example.com, name@example.com, .com, .co.uk)"
          aria-invalid="true"
          autocomplete="off"
          data-1p-ignore
          aria-describedby="prvemail-error"
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-36 flex items-center pr-6"
        >
          <svg
            v-if="errorMessage"
            class="h-5 w-5 text-red-500"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div :class="[
          isHard ? 'text-red-600 bg-red-50' : 'text-orange-500 bg-orange-50',
          'flex justify-between items-center space-x-1.5 text-xs text-gray-700 px-2 py-2 rounded-sm'
          ]">
          <span class="mx-1 w-8 text-center">{{  isHard ? 'Block' : 'Warn' }}</span>
          <button
            @click="isHard = !isHard"
            type="button"
            :class="[
              (isHard) ? 'bg-red-600' : 'bg-orange-500',
              'hover:opacity-80 relative inline-flex h-6 w-3 flex-shrink-0 cursor-pointer rounded-sm border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
              ]" role="switch" aria-checked="false">
            <span class="sr-only">Use setting</span>
            <span :class="[
                (isHard) ? 'translate-y-0' : 'translate-y-2.5',
                'pointer-events-none relative inline-block h-2.5 w-2.5 transform rounded-sm bg-white shadow ring-0 transition duration-200 ease-in-out'
                ]">
            </span>
          </button>
        </div>
        <button
          @click="onSubmit"
          :disabled="formInput.length < 4"
          type="button"
          class="text-sm disabled:opacity-50 disabled:cursor-default px-4 py-1.5 rounded-sm flex items-center justify-center bg-blue-600 h-10 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add
        </button>
      </div>
      <p v-if="errorMessage" class="mt-1 text-sm text-red-600" id="email-error">
        {{ errorMessage }}
      </p>
    </div>
  </section>
</template>
<script setup>
import { ref, computed } from "vue";

import { usePoliciesStore } from "@/modules/v2/store";
const POLICIES_STORE = usePoliciesStore();
// const SESSION_STORE = useSessionStore();

// const { errorMessage } = defineProps(["errorMessage"]); // moved here inside component
const emit = defineEmits(["action"]);

const formInput = ref("");
const errorMessage = ref("");

const emails = ref([]);
const isHard = ref(false);

const matches = computed(() => {
  const itemsList = POLICIES_STORE.denylist.getItemsList();
  let _matches = [];
  itemsList.forEach( item => {
    _matches.push(item.getMatch())
  })
  return _matches;
})

/**
 * @TODO
 * Use the JOI library for this. Temporarily using REGEX to
 * verify emails and domains. This is not ideal for production.
 */
const regexDomain = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,7}$/);
const regexEmail = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/);
const regexTLD = new RegExp(/^\.[a-z]+(\.[a-z]+)?$/);

/**
 * This is run whenever the user submits
 */
const onSubmit = () => {

  let isGoodToProceed = runValidation();
  if(isGoodToProceed){

    emails.value.forEach( email => {
      emit("action", email, isHard.value);
    });

    formInput.value = "";
    errorMessage.value = "";
  }

};

const onUpdate = () => {
  if(formInput.value.length == 0){
    errorMessage.value = '';
  }
}

const runValidation = () => {
  // store values here
  emails.value = splitEmails(formInput.value.toLowerCase());
  emails.value = [...new Set(emails.value)];

  let hasDuplicates = checkForDuplicates(emails.value)
  if(hasDuplicates){
    errorMessage.value = "A duplicate item has been found.";
    return false;
  }

  let validations = evaluateInputs(emails.value);
  console.log(validations);
  if(validations.every( e => e === true)){
    // all are valuid
    errorMessage.value = "";
    return true;
  }else{
    console.log(emails.value.length)
    if(emails.value.length > 1){
      errorMessage.value = "One or more email addresses or domain names or top level domains are invalid.";
    }else{
      errorMessage.value = "Invalid email address or domain name or top level domain.";
    }
    return false;
  }
}

const checkForDuplicates = (inputs) => {

let _duplicates = [];
inputs.forEach( input => {
  console.log(input)
  console.log(matches)
  if(matches.value.includes(input)){
    errorMessage.value = `A duplicate was found: ${errorMessage}`;
    _duplicates.push(input)
  }
});

if(_duplicates.length > 0){
  return true;
}else{
  return false;
}

}

const onSeparator = () => {
  console.log("Separator has been pressed.");
  runValidation();

}

// splits the email string
const splitEmails = (string) => {
  return string
    .split(/[,]|[;]|[\s]+/)     // segregate the emails by terminators via Regex
    .filter(el => el != "" );   // remove empty values after segregation, if any
}

// map out emails if each are valid or not
const evaluateInputs = (inputs) => {
  return inputs.map( input => (isValidInput(input)) ? true : false );
}

const isValidInput = (input) => {
  if (regexDomain.test(input)) {
    return true;
  } else if (regexEmail.test(input)) {
    return true;
  } else if (regexTLD.test(input)) {
    return true;
  } else {
    return false;
  }

}
/* Verify whether the input is in a correct DOMAIN or EMAIL format. */


</script>