<template>
    <div class="flex justify-between space-x-2 px-4 h-12 items-center transition-all">
    <div class="flex items-center space-x-3 w-full">
        {{ isGroupEnabled.value }}
        <input @change="isGroupChecked ? emit('uncheckAllItems') : emit('checkAllItems')" :checked="isGroupChecked" aria-describedby="rule" name="rule" type="checkbox" class="cursor-pointer mr-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500">
        <!-- TODO: CLEAN THIS UP -->
        <!-- <button 
        @click="isGroupEnabled ? emit('disableAllItems') : emit('enableAllItems')"
        type="button" 
        :class="[
            (isGroupEnabled) ? 'bg-blue-600' : 'bg-gray-200',
            'relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
            ]" role="switch" aria-checked="false">
        <span class="sr-only">Use setting</span>
        <span :class="[
            (isGroupEnabled) ? 'translate-x-3' : 'translate-x-0',
            'pointer-events-none relative inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            ]">
            
        </span>
        </button> -->
    </div>
    
    <div class="flex space-x-2">

        <button 
            v-if="!removeAllRequested"
            @click="removeAllRequested = true"
            type="button" 
            class="text-red-600">

            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
        </button>
        <div v-else class="flex space-x-2">
            <button @click="emit('removeAllItems')" type="button" class="rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
                Confirm
            </button>
            <button @click="removeAllRequested = false" type="button" class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
                Keep
            </button>
        </div>
    </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
const { isGroupEnabled, isGroupChecked, groupName } = defineProps(["isGroupEnabled", "isGroupChecked", "groupName"]); // moved here inside component
const emit = defineEmits(["enableAllItems", "disableAllItems", "checkAllItems", "uncheckAllItems", "removeAllItems", "updateGroupName"]);

const removeAllRequested = ref(false);

const editGroupName = ref(false);
const tempGroupName = ref('');
const isInputError = ref(false);

const evaluateEdit = () => {
    if(isInputError.value.length < 4){
        isInputError.value = true;
    }else{
        emit('updateGroupName', tempGroupName.value);
        editGroupName.value = false;
    }
    
}

</script>