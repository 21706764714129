<template>
  <th
    scope="col"
    :class="[
      props.isFirst ? 'w-3/12 pl-6' : '',
      props.isLast ? 'w-1/12' : '',
      props.id == 'sender' ? 'w-2/12' : '',
      props.id == 'recipients' ? 'w-3/12' : '',
      props.id == 'action' ? 'w-3/12' : '',
      'px-4 py-3.5 text-left text-sm font-semibold text-gray-900 overflow-visible',
    ]"
  >
    <div class="w-full flex justify-between items-center">
      <!-- Name Only: When resized to small screen -->
      <div class="truncate block xl:hidden">{{ props.name }}</div>

      <!-- Name + Info: On normal large screen -->
      <div class="hidden xl:flex items-center space-x-1">
        <div class="truncate">{{ props.name }}</div>
        <div v-if="props.info">
          <InfoTooltip :message="props.info" />
        </div>
      </div>

      <!-- Selector Icon -->
      <div
        v-if="props.canSort"
        class="hidden xl:flex items-center space-x-0.5 items-center"
      >
        <!-- <div
          @click="handleClickSort(props.id)"
          :class="[
            STORE_EVENT.sortedBy.id == props.id &&
            STORE_EVENT.sortedBy.type != 'none'
              ? 'bg-blue-50 text-blue-500'
              : '',
            'hidden xl:block cursor-pointer hover:opacity-75',
          ]"
        >
          <SelectorIcon
            v-if="
              STORE_EVENT.sortedBy.id == props.id &&
              STORE_EVENT.sortedBy.type == 'none'
            "
            class="h-4 w-4"
          />
          <SortAscendingIcon
            v-else-if="
              STORE_EVENT.sortedBy.id == props.id &&
              STORE_EVENT.sortedBy.type == 'asc'
            "
            class="h-4 w-4"
          />
          <SortDescendingIcon
            v-else-if="
              STORE_EVENT.sortedBy.id == props.id &&
              STORE_EVENT.sortedBy.type == 'desc'
            "
            class="h-4 w-4"
          />
          <SelectorIcon v-else class="h-4 w-4" />
        </div> -->
      </div>
    </div>
  </th>
</template>

<script>
// import { useEventStore } from "@/stores/events";
// const STORE_EVENT = useEventStore();

import {
  InformationCircleIcon,
  SelectorIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from "@heroicons/vue/outline";

import InfoTooltip from "@/components/tooltip/InfoTooltip";

export default {
  props: ["props"],
  components: {
    // Icons
    InformationCircleIcon,
    SelectorIcon,
    SortAscendingIcon,
    SortDescendingIcon,
    // Custom Components
    InfoTooltip, 
  },
  data(){
    return {
      // STORE_EVENT: STORE_EVENT
    }
  },
   methods: {
    // handleClickSort: (id) => {
    //   /**
    //    * @TODO Use ENUMS instead of string literals
    //    */
    //   var type = "";
    //   if (STORE_EVENT.sortedBy.id != id){
    //     /**
    //      * If the user is about to sort with a new column,
    //      * always return asc by default.
    //      */
    //     type = "asc";
    //   }else{
    //     /**
    //      * Else, the user is switching order of sort
    //      * within the same column
    //      */
    //     if(STORE_EVENT.sortedBy.type == 'asc') {
    //       type = "desc"
    //     }else if(STORE_EVENT.sortedBy.type == 'desc') {
    //       type = "none";
    //     }else{
    //       type = "asc";
    //     }
    //   }
      
    //   STORE_EVENT.updateSortedBy({
    //     id: id,
    //     type: type,
    //   });
      
    // }
    
   }
}


</script>
