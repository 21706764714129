<template>

  <div class="px-4 sm:px-6 lg:px-8 mt-6 mb-8">
    
    

      <header class="flex justify-between">
        <div class="w-full">
          <h1 class="sr-only">Logs</h1>
          <h1 class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate">
            Logs
          </h1>
          <p class="text-sm font-normal text-gray-600 w-5/6 my-2">
            Logs show detailed analyses of potential threats detected in all <em>outbound</em> email scanned by Preava Prevent.
          </p>
        </div>
      </header>

      <div class="h-9 my-2 flex justify-between items-end space-x-4">
        <div class="text-sm font-normal text-gray-600">
		<!-- Showing {{ STORE_SESSION.events.length }} items out of {{ STORE_SESSION.count }} in {{ enumToString(ORDERS, STORE_SESSION.filter.order).toLowerCase() }} order. -->
		Showing {{ STORE_SESSION.events.length }} items in {{ enumToString(ORDERS, STORE_SESSION.filter.order).toLowerCase() }} order.
          </div>
        <div class="flex flex-wrap text-right">
          <div class="isolate inline-flex">
            <button
              :disabled="STORE_SESSION.isWaiting"
              @click="handleRefreshUsers()"
              type="button"
              :class="[
                'rounded-l-md',
                `text-gray-600 hover:text-gray-900`,
                'relative inline-flex items-center bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 space-x-1'
              ]"
            >
              <RefreshIcon class="w-4 h-4"/>
              <span>Refresh Logs</span>
            </button>
          </div>
          <div class="isolate inline-flex">
            <button
              :disabled="STORE_SESSION.isWaiting"
              @click="openSearchModal = true"
              type="button"
              :class="[
                '-ml-1 rounded-r-md',
                `text-gray-600 hover:text-gray-900`,
                'relative inline-flex items-center bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 space-x-1'
              ]"
            >
              <FilterIcon class="w-4 h-4"/>
              <span>Show Filters</span>
            </button>
          </div>
        </div>
      </div>
      
      
    

    <div class="mt-5 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          class="inline-block w-full py-2 align-middle md:px-6 lg:px-8"
        >
          <div v-if="!STORE_SESSION.isWaiting">
            <div v-if="STORE_SESSION.events.length > 0">
              <EventsTable :events="STORE_SESSION.events" class="table-wrapper" />
            </div>
            <div v-else class="overflow-show border border-gray-300 mb-5 mt-4 h-48 flex items-center justify-center text-sm text-gray-500">
              <div class="text-center py-20">
                <svg class="mx-auto h-12 w-12 text-gray-400"
                  aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path></svg>

                <h3 class="mt-2 text-sm font-medium text-gray-900">
                  No Events Found
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Try modifying the Events filter to see more events.
                </p>
                
              </div>
            </div>
          </div>
          <div v-else class="table-wrapper">
            <EventsTableLoading />
          </div>
        </div>
      </div>
    </div>
  </div>

  <EventsInfoModal v-if="!STORE_SESSION.isWaiting"  />
  <EventsSearchModal :open="openSearchModal" @close="openSearchModal = false" />
  <EventsDbMaintenanceModal :open="openDbMaintenanceModal" @close="openDbMaintenanceModal = false" />
  
</template>
<script setup>
// Default Components
import DefaultHeader from "@/components/header/DefaultHeader";
import DefaultSidebar from "@/components/sidebar/DefaultSidebar";
import DefaultNotification from "@/components/notification/DefaultNotification";
import ToastNotification from "@/components/notification/ToastNotification";
import EventsTableLoading from "./EventsTableLoading.vue";

import { useSessionStore } from "@/stores/sessionv2";
import { ref } from "vue";
import moment from "moment";

import { enumToString } from "@/utilities/helpers";

import { Order as ORDERS } from "@preava/preava-prevent-api-grpc-web-js/filters_pb";

import EventsTable from "./EventsTable.vue";
import EventsSearchModal from "./EventsSearchModal";
import EventsDbMaintenanceModal from "./EventsDbMaintenanceModal";
import EventsInfoModal from "./EventsInfoModal";

// import { FilterIcon, RefreshIcon, DatabaseIcon } from "@heroicons/vue/solid";
import { FilterIcon, RefreshIcon, DatabaseIcon } from "@heroicons/vue/outline";

const openSearchModal = ref(false);
const openInfoModal = ref(false);
const openDbMaintenanceModal = ref(false);

const STORE_SESSION = useSessionStore();
if(STORE_SESSION) STORE_SESSION.initializeSession();

let payload = {};
payload.dateStart = moment().startOf("year").unix();
payload.dateEnd = moment().endOf("day").unix();
payload.limit = 50;
payload.order = ORDERS.DESCENDING;
console.log({ payload });
STORE_SESSION.updateFilter(payload);
STORE_SESSION.fetchEvents(payload);
// STORE_SESSION.countLogs();

const handleRefreshUsers = () => {
  STORE_SESSION.fetchEvents(STORE_SESSION.filter);
};
</script>  

<style scoped>
.table-wrapper {
  @apply truncate shadow ring-1 ring-black ring-opacity-5;
}

.prv-events .refresh-button {
  @apply flex-shrink-0 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-500 disabled:cursor-auto disabled:opacity-25 p-0 flex ml-2;
}

.prv-events .refresh-button .button-icon {
  @apply h-5 w-5 text-white mr-2;
}
</style>
