import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { getIdentity, activate } from "@/modules/v2/adapter";
import { Role as ROLE } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

const PREFIX = '💽STORE⟹USER';

export const useUserStore = defineStore('v2_user', () => {

    /**
     * The user information of the logged in user
     */
    const userIdentity = ref(null);
    const userRole = ref(null);
    const isAdminUser = computed(() => {
        return (userRole.value == ROLE.ROLE_ADMIN ) ? true : false
    });

    /**
     * Activate the logged in user and get its identity.
     */
    const getLoggedInUser = async (traceId) => {
        console.log(`[${PREFIX}]: Preava Prevent will now activate and fetch the identity of logged in user...`);

        /**
         * Activate the user first and get their role.
         */
        let activationRes = await activate(traceId);
        if(!activationRes) return false;
        userRole.value = activationRes.getRole();
        console.log(`[${PREFIX}]: Role of logged in user has been set.`);

        /**
         * Get the user's identity.
         */
        let identityRes = await getIdentity(traceId);
        if(!identityRes) return false;
        userIdentity.value = identityRes;
        console.log(`[${PREFIX}]: Identity of logged in user has been set.`);

        /**
         * Return to the UI with all STORE data completely set.
         */
        return true;
    }

    return { 
        userIdentity, userRole, isAdminUser,
        getLoggedInUser
    }
})