import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { createRule, listRule, updateRule, deleteRule } from "@/modules/v2/adapter";
import { IMPLEMENTATION_TYPE_MAP } from "@/modules/v2/utilities";
import { RulePayload, RuleData, AutoCombinationsEmail } from "@preava/preava-prevent-api-grpc-web-js/public_pb";
import { Email, EmailList } from "@preava/preava-prevent-api-grpc-web-js/types_pb";
import { ObjectId } from "@preava/preava-prevent-api-grpc-web-js/ids_pb";
import { Status, Logging } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";

const PREFIX = '💽STORE⟹RULES';

export const useRulesStore = defineStore('v2_rules', () => {

    const selectedRuleData = ref(null); // an empty rule data by default
    const selectedRuleDataObject = computed(() => selectedRuleData.value.toObject()); // an empty rule data by default

    const rules = ref([]);  // all the 'rules' returned by the backend
    const implementations = ref([]); // all the 'implementations' taken from the protobuff
    const rule_implementation_map = ref(new Map()); // a implementation=type mappping of each rule

    const rules_rules = computed( ()=> {
        let _tmp = [];
        rules.value.forEach( rule => {
            let type = rule_implementation_map.value.get(rule.getItem().getObjectId().getValue()).type;
            if(type == 'RULE'){ _tmp.push(rule) };
        });
        return _tmp;
    })

    const rules_policies = computed( ()=> {
        let _tmp = [];
        rules.value.forEach( rule => {
            let type = rule_implementation_map.value.get(rule.getItem().getObjectId().getValue()).type;
            if(type == 'POLICY'){ _tmp.push(rule) };
        });
        return _tmp;
    })

    /**
     * Get all the rules from the backend
     */
    const getAllRules = async (traceId) => {
    // const getAllRules = async (traceId, orgId) => {
        console.log(`[${PREFIX}]: Preava Prevent will now activate and fetch the identity of logged in user...`);

        /**
         * stream all the rules
         */
        let listRuleRes = await listRule(traceId);
        if(!listRuleRes) return false;
        rules.value = listRuleRes;

        /**
         * Once the rules are fetched, map out the 
         * implementation and implementation type 
         * (i.e. policy or rule).
         */
        // let _m = new Map();
        // listRuleRes.forEach( rule => {
        //     let ruleId = rule.getObjectId();
        //     let implementation = getRuleImplementation(rule.toObject())
        //     let type = getImplementationType(implementation)
        //     _m.set(ruleId, {
        //         implementation: implementation,
        //         type: type
        //     });
        // })
        // rule_implementation_map.value = _m;

        /**
         * Return to the UI with all STORE data completely set.
         */
        return true;
    }

     /**
     * Get all implementations from the RuleData proto
     * and remove 'IMPLEMENTATION_NOT_SET'
     */
    const getAllImplementations = () => {
        let _implementations = Object.keys(RuleData.ImplementationCase);
        const index = _implementations.indexOf('IMPLEMENTATION_NOT_SET');
        _implementations.splice(index, 1); // remove the option 'IMPLEMENTATION_NOT_SET'
        implementations.value = _implementations;
    }

    /**
     * @TODO:
     * Since backend cannot return the 'type' of implementation: 
     * whether it's a POLICY or a RULE, then we need to map it ourselves
     * locally. This mapping is found in `constants.js`.
     */
    const getImplementationType = (implementation) => {
        return IMPLEMENTATION_TYPE_MAP.get(implementation)
    }
  
    /**
     * Get the oneOf implementation of each rule.
     * Need to scan for any defined implementation through all 
     * implementation cases from the RuleData proto
     */
    const getRuleImplementation = (rule) => {
        let result = ''
        Object.keys(RuleData.ImplementationCase).forEach( implementation => {
          if(rule.data[implementation.toLowerCase()]){  
            result = implementation;
          }
        });
        return result;
    }

    /**
     * Retreives all implementations according to 'type'.
     * This is used for when a user wants to create a new rule and 
     * we show them the options of implementation types for a rule.
     */
    const getImplementationsByType = (TYPE) => {
        let _ruleImplementations = [];
        implementations.value.forEach( (imp) => {
            if(IMPLEMENTATION_TYPE_MAP.get(imp) == TYPE){
                _ruleImplementations.push(imp)
            }
        })
        return _ruleImplementations;
    }

    const pushRuleChanges = async (traceId, orgId, rule) => {
        
        console.log(`[${PREFIX}]: Updating rule ${rule.getItem().getObjectId().getValue()}...`);
        let updateRuleRes = await updateRule(traceId, orgId, rule);
        if(!updateRuleRes){
            return false;
        }else{
            getAllRules(traceId, orgId);
        }
        
    }

    const pushRuleDelete = async (traceId, orgId, rule) => {
        
        console.log(`[${PREFIX}]: Deleting rule ${rule.getItem().getObjectId().getValue()}...`);
        let deleteRuleRes = await deleteRule(traceId, orgId, rule);
        if(!deleteRuleRes){
            return false;
        }else{
            getAllRules(traceId, orgId);
        }
        
    }

    const updateSelectedRuleData = (_ruleData) => {
        selectedRuleData.value = _ruleData;
    }

    const resetSelectedRuleData = () => {
        selectedRuleData.value = null;
    }

    const pushCreateRule = async (traceId) => {
        console.log(`[${PREFIX}]: Creating a new rule `);
        /**
         * For now this API is not yet callable
         */

        // console.log(selectedRuleData.value)
        // console.log(selectedRuleData.value.toObject())


        let createRuleRes = await createRule(traceId, selectedRuleData.value);
        console.log('createRuleRes', createRuleRes)
        // if(!createRuleRes){
        //     return false;
        // }else{
        //     getAllRules(traceId, orgId);
        // }

        /**
         * Return to the UI with all STORE data completely set.
         */
        return true;
    }

    const mockFetchRules = () => {

        

        // var _rules = [];
        // for (let i = 0; i < 5; i++) {
        //     let rulePayload = new RulePayload();
        //     rulePayload.setObjectId(new ObjectId().setValue(`mockid-${i+1}`));
            
        //     let ruleData = new RuleData();
        //     ruleData.setName(`MOCK RULE NAME ${i+1}`);
        //     ruleData.setDescription(`MOCK RULE DESCRIPTION ${i+1}`);
        //     ruleData.setPriority(i+1);
        //     ruleData.setStatus(Status.STATUS_ENABLED);
        //     ruleData.setPriority(Logging.LOGGING_ENABLED);

        //     let autoCombinationsEmail = new AutoCombinationsEmail();
        //     let emailList = new EmailList();

        //     emailList.setValuesList([
        //         new Email().setValue(`john${i+1}@test.com`), 
        //         new Email().setValue(`mike${i+1}@test.com`)
        //     ])
            
        //     autoCombinationsEmail.setEmailCombinationsList([emailList])
            
        //     // EmailList
        //     ruleData.setAutocombinationsemail(autoCombinationsEmail)
        //     rulePayload.setData(ruleData)

        //     _rules.push(rulePayload)

        // }
        
        // rules.value = _rules;
        return true
        
    }

    return { 
        rules, implementations, rule_implementation_map, rules_rules, rules_policies,
        getAllRules, getRuleImplementation, getAllImplementations, getImplementationsByType, getImplementationType, 
        pushRuleChanges, pushRuleDelete, pushCreateRule,


        updateSelectedRuleData, resetSelectedRuleData,
        selectedRuleData, selectedRuleDataObject,

        mockFetchRules
    }
})