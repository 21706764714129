<template>
  <table class="w-full divide-y divide-gray-300 table-fixed z-0 overflow-visible ">
    <thead class="bg-gray-50 overflow-visible">
      <EventsTableHeader />
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white ">
      <EventsTableData 
        v-for="event in events" 
        :key="event.transactionId.value" 
        :event="event" 
      />
    </tbody>
  </table>
</template>

<script setup>
import EventsTableHeader from "./EventsTableHeader.vue";
import EventsTableData from "./EventsTableData.vue";
const props = defineProps({
  events: Array
})

console.log(props.events)
</script>
