<template>
  <div v-if="!SESSION_STORE.isInitializing" class="flex flex-col h-full px-8 py-6 bg-gray-50">
    <header class="flex justify-between">
      <div class="w-full">
        <h1 class="sr-only">Allowlist</h1>
        <h1 class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate">
          Allowlist
        </h1>
        <p class="text-sm font-normal text-gray-600 w-3/4 my-2">
          Add domain names and individual email addresses that will never
          trigger a risk alert below. Allowlist entries are always considered
          safe and will override all denylist entries, but not machine learning
          (ML), artificial intelligence (AI), or other statistical models that
          are denoted as such.
        </p>
      </div>
    </header>
    <main :class="[
      SESSION_STORE.isWaiting ? 'pointer-events-none opacity-50' : 'pb-9',
    ]">

      <EmailDomainInput @action="addItem" class="mt-8" />

      <!-- Group Controls -->
      <div :class="[
        (checkedItemsIndeces.length > 0) ? 'translate-y-0 opacity-100' : '-translate-y-5 opacity-0 pointer-events-none h-0',
        'w-full bg-white py-2 transform transition-all duration-400 ease-in-out h-11'
      ]">
        <div class="flex space-x-2 items-center text-gray-600">
          <h2 class="text-sm">Bulk Actions</h2>
          <button @click="enableCheckedItemsIndeces" type="button"
            class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Enable</button>
          <button @click="disableCheckedItemsIndeces" type="button"
            class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Disable</button>
          <button v-if="!removeCheckedRequested" @click="removeCheckedRequested = true" type="button"
            class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Delete</button>
          <div v-else class="space-x-2">
            <button @click="removeCheckedRequested = false" type="button"
              class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
              Keep
            </button>
            <button @click="removeItemsFromCheckedIndeces" type="button"
              class="rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
              Confirm
            </button>
          </div>
        </div>
      </div>

      <!-- {{  checkedItemsIndeces }} -->
      <!-- <ul>
        <li v-for="item, i in checkedItemsIndeces" :key="i">
        {{ item }}</li>
      </ul> -->

      <!-- Items List -->
      <section v-if="POLICIES_STORE.allowlist" class=" bg-white border border-gray-300 transition-all duration-400">
        <div v-if="POLICIES_STORE.allowlist.getItemsList().length == 0"
          class="h-48 text-sm flex items-center justify-center text-gray-400">
          No domains, emails or top level domains added yet.
        </div>
        <ul v-else class="flex flex-col">
          <!-- List Item Group Header -->
          <li class="border-b border-gray-300 text-sm text-gray-600 bg-gray-100">
            <GroupHeaderUI :groupName="POLICIES_STORE.allowlist.getItemsList()[0].getGroup()"
              :isGroupChecked="isGroupChecked" :isGroupEnabled="isGroupEnabled" @enableAllItems="enableAllItems"
              @disableAllItems="disableAllItems" @checkAllItems="checkAllItems" @uncheckAllItems="uncheckAllItems"
              @removeAllItems="removeAllItems" @updateGroupName="updateGroupName" />
          </li>
          <li v-for="item, index  in POLICIES_STORE.allowlist.getItemsList()" :key="index" :class="[
            (index == 0) ? '' : 'border-t border-gray-300',
            'text-sm text-gray-600'
          ]">
            <div class="flex justify-between space-x-2 px-4 h-12 items-center transition-all">

              <!-- Left Items -->
              <div class="flex items-center space-x-3 w-full">

                <!-- Checkbox -->
                <input @change="
                  checkedItemsIndeces.includes(index) ?
                    removeCheckedItem(index) :
                    addCheckedItem(index)" :checked="checkedItemsIndeces.includes(index)" aria-describedby="rule"
                  name="rule" type="checkbox"
                  class="cursor-pointer mr-1 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500">

                <!-- Toggle Switch -->
                <button @click="toggleEnabled(item.getEnabled(), index)" type="button" :class="[
                  (item.getEnabled()) ? 'bg-blue-600' : 'bg-gray-200',
                  'relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                ]" role="switch" aria-checked="false">
                  <span class="sr-only">Use setting</span>
                  <span :class="[
                    (item.getEnabled()) ? 'translate-x-3' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  ]">

                  </span>
                </button>



                <!-- Item Edit -->
                <input v-if="currentItemEdited != null && currentItemEdited == index"
                  @keyup.enter="evaluateEdit(item.getMatch())" v-model="tempItem" type="text" name="prvemail"
                  id="prvemail" :class="[
                    isInputError
                      ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'text-gray-600 placeholder-gray-300 focus:border-gray-500 focus:ring-gray-500 border-gray-300',
                    'block w-full px-2 py-1 focus:outline-none sm:text-sm',
                  ]" placeholder="example.com, name@example.com, .com, .co.uk" aria-invalid="true" autocomplete="off"
                  data-1p-ignore aria-describedby="prvemail-error" />
                <!-- Item Display -->
                <!-- <ItemDisplay v-else :item="item"/> -->
                <span v-else :class="[
                  item.getEnabled() ? '' : 'text-gray-200',
                  'flex space-x-2 items-center transition-all', 'pl-1'
                ]">
                  {{ item.getMatch() }}
                </span>

              </div> <!-- End of Left Items -->

              <div class="flex">
                <span
                  class="whitespace-nowrap inline-flex items-center rounded-md bg-gray-50 px-2 py-0.5 text-xs text-gray-600 ring-1 ring-inset ring-gray-400 mr-2 my-0.5">
                  {{ checkEntryType(item.getMatch()) }}
                </span>
              </div>


              <div class="flex items-center space-x-4">

                <div v-if="currentItemEdited != null && currentItemEdited == index" class="flex items-center space-x-2">
                  <button @click="evaluateEdit(item.getMatch())" type="button"
                    class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
                    Update
                  </button>
                  <button @click="currentItemEdited = null" type="button"
                    class="rounded bg-gray-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
                    Cancel
                  </button>
                </div>
                <button v-else @click="prepEdit(item, index)" type="button" class="text-gray-600">
                  <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                  </svg>
                </button>


                <!-- Delete Button -->
                <button v-if="!deleteQueue.includes(index)" @click="deleteQueue.push(index)" type="button"
                  class="text-red-600">

                  <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                </button>
                <div v-else class="flex items-center space-x-2">
                  <button @click="removeItem(index)" type="button"
                    class="rounded bg-red-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
                    Delete
                  </button>
                  <button @click="removeItemFromDeleteQueue(index)" type="button"
                    class="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white hover:opacity-75">
                    Keep
                  </button>
                </div>
              </div>

            </div>

          </li>
        </ul>
      </section>

    </main>
  </div>
  <div v-else class="h-full">
    <AllowlistSkeleton />
  </div>
  <div v-if="SESSION_STORE.isWaiting" class="absolute bottom-3 right-5 py-3">
    <span
      class="border border-gray-100 shadow-md bg-gray-50 text-gray-500 text-xs rounded-md px-4 py-2 flex items-center">
      <LoaderIcon class="animate-spin flex-shrink-0 mr-2 h-4 w-4 text-gray-400" />Please wait...
    </span>
  </div>

  <!-- Global notification live region, render this permanently at the end of the document -->
  <div v-if="SESSION_STORE.errorMessage" aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-end sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <!-- <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
              </div> -->
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <!-- <p class="text-sm font-medium text-gray-900">Error!</p> -->
                <p class="text-sm text-red-500">{{ SESSION_STORE.errorMessage }}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="show = false"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <!-- <XMarkIcon class="h-5 w-5" aria-hidden="true" /> -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>

                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script setup>

// Adapter and User classes, etc.
import { activate } from '@/adapter'
import { Role as ROLE } from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { v4 } from "uuid";
import ROUTER from "@/router";

/**
 * Import the necessary module components
 */
import { ref, computed } from "vue";
import DefaultHeader from "@/components/header/DefaultHeader"; // from global
import DefaultSidebar from "@/components/sidebar/DefaultSidebar"; // from global
import LoaderIcon from '@/assets/icons/LoaderIcon.vue';
import EmailDomainInput from "./components/EmailDomainInput.vue";
import GroupHeaderUI from "./components/GroupHeaderUI.vue";
import ItemDisplay from "./components/ItemDisplay.vue";
import AllowlistSkeleton from "./AllowlistSkeleton.vue";

import ToastNotification from "@/components/notification/ToastNotification.vue";

/**
 * Import Preava dependencies
 */
import { GRPC_ERROR, enumToString } from "@/modules/v2/utilities";
import { AllowList } from "@preava/preava-prevent-api-grpc-web-js/public_pb";

/**
 * Import Store dependencies
 */
import { useSessionStore, usePoliciesStore } from "@/modules/v2/store";
const POLICIES_STORE = usePoliciesStore();
const SESSION_STORE = useSessionStore();

const show = ref(false)

/**
 * @todo
 * These are initial default values.
 */
// const DEFAULT_GROUP = 'DEFAULT_GROUP';


/**
 * UI variables
 */
const checkedItemsIndeces = ref([]);
const isGroupChecked = ref(false);

/**
 * Group Toggle Functions and Variables Here
 */
const isGroupEnabled = computed(() => {
  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  return itemsList.every(e => e.getEnabled() === true);
})

const enableAllItems = async () => {
  SESSION_STORE.isWaiting = true;
  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  itemsList.forEach(item => {
    item.setEnabled(true);
  })
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  SESSION_STORE.isWaiting = false;
}

const disableAllItems = async () => {
  SESSION_STORE.isWaiting = true;
  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  itemsList.forEach(item => {
    item.setEnabled(false);
  })
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  SESSION_STORE.isWaiting = false;
}

const updateGroupName = async (groupName) => {
  SESSION_STORE.isWaiting = true;
  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  itemsList.forEach(item => {
    item.setGroup(groupName);
  })
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  SESSION_STORE.isWaiting = false;
}

/**
 * Checked Items Functions and Variables Here
 */
const checkAllItems = () => {
  console.log(checkedItemsIndeces.value)
  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  let _temp = []
  for (let i = 0; i < itemsList.length; i++) {
    _temp.push(i)
  }
  checkedItemsIndeces.value = _temp;
  isGroupChecked.value = true;
}

const uncheckAllItems = () => {
  checkedItemsIndeces.value = [];
  isGroupChecked.value = false;
}

const addCheckedItem = (item) => {
  checkedItemsIndeces.value.push(item);
}

const removeCheckedItem = (itemIndex) => {
  const i = checkedItemsIndeces.value.findIndex(e => e == itemIndex);
  checkedItemsIndeces.value.splice(i, 1);
}

const enableCheckedItemsIndeces = async () => {
  SESSION_STORE.isWaiting = true;
  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  checkedItemsIndeces.value.forEach(itemIndex => {
    itemsList[itemIndex].setEnabled(true);
  })
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  uncheckAllItems();
  SESSION_STORE.isWaiting = false;
}

const disableCheckedItemsIndeces = async () => {
  SESSION_STORE.isWaiting = true;
  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  checkedItemsIndeces.value.forEach(itemIndex => {
    itemsList[itemIndex].setEnabled(false);
  })
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  uncheckAllItems();
  SESSION_STORE.isWaiting = false;
}

/**
 * Removing Items UI functions
 */

const deleteQueue = ref([]);
const removeCheckedRequested = ref(false);

const removeItemFromDeleteQueue = (index) => {
  const i = deleteQueue.value.findIndex(e => e == index);
  deleteQueue.value.splice(i, 1);
}

/**
 * Fixed bug
 */
const removeItemsFromCheckedIndeces = async () => {
  SESSION_STORE.isWaiting = true;

  const itemsList = [...POLICIES_STORE.allowlist.getItemsList()];
  const itemsForDelete = [];

  console.log(itemsList)

  checkedItemsIndeces.value.forEach((itemIndex) => {
    itemsForDelete.push(itemsList[itemIndex])
  })

  itemsForDelete.forEach((item) => {
    let i = itemsList.findIndex(e => e == item)
    itemsList.splice(i, 1)
  })

  POLICIES_STORE.allowlist.setItemsList(itemsList);
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);

  uncheckAllItems();
  removeCheckedRequested.value = false;
  SESSION_STORE.isWaiting = false;

}

const removeAllItems = async () => {
  SESSION_STORE.isWaiting = true;
  POLICIES_STORE.allowlist.setItemsList([]);
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  deleteQueue.value = [];
  uncheckAllItems();
  SESSION_STORE.isWaiting = false;
}

/**
 * For editing
 */
const currentItemEdited = ref(null);
const isInputError = ref(false);
const tempItem = ref('');

/**
 * @TODO
 * Use the JOI library for this. Temporarily using REGEX to
 * verify emails and domains. This is not ideal for production.
 */
const regexDomain = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,7}$/);
const regexEmail = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/);
const regexTLD = new RegExp(/^\.[a-z]+(\.[a-z]+)?$/);

const checkEntryType = (entry) => {
  if (regexDomain.test(entry)) {
    return "Domain Name"
  } else if (regexEmail.test(entry)) {
    return "Email Address"
  } else if (regexTLD.test(entry)) {
    return "Top Level Domain"
  } else {
    return "Unknown"
  }
}

const evaluateEdit = async (originalValue) => {

  SESSION_STORE.isWaiting = true;

  // check for any duplicates
  let il = POLICIES_STORE.allowlist.getItemsList();
  let _matches = [];
  il.forEach(item => {
    _matches.push(item.getMatch())
  })
  console.log('_matches', _matches)
  console.log('tempItem.value', tempItem.value)
  if (_matches.includes(tempItem.value) && tempItem.value != originalValue) {
    console.log("User added a duplicate.");
    SESSION_STORE.errorMessage = "User added a duplicate.";
    show.value = true;
    isInputError.value = true;
    SESSION_STORE.isWaiting = false;
    return;
  }

  /* Verify whether the input is in a correct DOMAIN or EMAIL format. */
  if (regexDomain.test(tempItem.value)) {
    console.log("User added a valid domain.");
    isInputError.value = false;
  } else if (regexEmail.test(tempItem.value)) {
    console.log("User added a valid email address.");
    isInputError.value = false;
  } else if (regexTLD.test(tempItem.value)) {
    console.log("User added a valid top level domain.");
    isInputError.value = false;
  } else {
    SESSION_STORE.errorMessage = "Incorrect format.";
    show.value = true;
    isInputError.value = true;
    SESSION_STORE.isWaiting = false;
    return;
  }

  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  itemsList[currentItemEdited.value].setMatch(tempItem.value);

  POLICIES_STORE.allowlist.setItemsList(itemsList);
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);

  tempItem.value = '';
  isInputError.value = false;
  show.value = false;
  currentItemEdited.value = null;

  SESSION_STORE.isWaiting = false;
}

const prepEdit = (item, index) => {
  tempItem.value = item.getMatch()
  currentItemEdited.value = index;
}


/**
 * In here, we initialize the item; we also set enabled to true and the group to 'none'.
 * We add the item to the original items list before pushing it to the backend.
 */
const addItem = async (match) => {
  SESSION_STORE.isWaiting = true;

  const item = new AllowList.Item();
  item.setMatch(match);
  item.setGroup('');
  item.setEnabled(true);

  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  itemsList.push(item)

  POLICIES_STORE.allowlist.setItemsList(itemsList);
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  await POLICIES_STORE.fetchAllowList(SESSION_STORE.traceId);
  SESSION_STORE.isWaiting = false;
}

/**
 * In here, we get the index of the specific item and remove it from the items list.
 * We then push the change to the backend.
 */
const removeItem = async (index) => {
  SESSION_STORE.isWaiting = true;
  removeItemFromDeleteQueue(index);

  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  itemsList.splice(index, 1);

  POLICIES_STORE.allowlist.setItemsList(itemsList);
  await POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  await POLICIES_STORE.fetchAllowList(SESSION_STORE.traceId);
  SESSION_STORE.isWaiting = false;
}

/**
 * In here, we get the index of the specific item and toggle the 'enabled' setting.
 * We then push the change to the backend.
 */
const toggleEnabled = (value, index) => {
  SESSION_STORE.isWaiting = true;

  const itemsList = POLICIES_STORE.allowlist.getItemsList();
  itemsList[index].setEnabled(!value);

  POLICIES_STORE.pushAllowlistChanges(SESSION_STORE.traceId);
  SESSION_STORE.isWaiting = false;
}


/**
 * @TODO
 * Move this to the router and use store for this.
 * Make this reusable
 */
const adminGuard = async () => {
  return new Promise(async (resolve, reject) => {
    activate(v4())
      .then(async result => {
        console.log({ result })
        console.log(result.getRole())
        if (result.getRole() === ROLE.ROLE_ADMIN) {
          // If admin, proceed normally
          console.log("%cYou have the right to view this page.", "color: #B4D455")
          resolve();
        } else if (result.getRole() === ROLE.ROLE_USER) {
          // Else if user, redirect immediately to /endpoints page
          console.log("%cYou don't have the right to view this page. Redirecting...", "color: #FE2020")
          ROUTER.push({ path: '/integrations' })
        }
      })
  });
}

/**
 * In here, we initialize the view.
 * First we initialize the session and create a new trace Id.
 * Afterwards, we fetch the allowlist from the backend.
 * Once fetched, the UI will automatically display them.
 */
const initialize = async () => {
  SESSION_STORE.isInitializing = true;
  console.log("%cIntializing Allowlist Tab", "color:#b4d455");

  try {
    await adminGuard();
    await SESSION_STORE.createNewTraceId();
    await POLICIES_STORE.fetchAllowList(SESSION_STORE.traceId);

    console.log(POLICIES_STORE.allowlist)
  } catch (err) {
    /**
     * Handle this error in the UI. Set the error message in the store,
     * that way other components can access it.
     */
    SESSION_STORE.errorMessage = `[${enumToString(GRPC_ERROR, err.code)}]: ${err.message
      }`;
    SESSION_STORE.errorMessage = "Something happened"
  }
  SESSION_STORE.isInitializing = false;
  SESSION_STORE.isWaiting = false;
};

initialize();
</script>
